import { Modal, Spinner } from "react-bootstrap";
import { UserType } from "../../types/UserType";
import Styles from "../../css/profile/releasePaymentModal.module.css"
import { Alert, Button, TextField } from '@mui/material';
import { useState } from "react";
import { SubmissionType } from "../../types/SubmissionType";
import { EmailType } from "../../types/EmailType";
import { sendEmail } from "../../services/emailService";
import { CardType } from "../../types/CardType";
import { updateCard } from "../../services/cardService";
import { ReportSubmissionModalEmailToBMI, ReportSubmissionModalEmailToCustomer } from "../../Text/emails";
import "../../css/theme.css"

interface Props {
    showModal: boolean;
    onClose: () => void;
    currentUser: UserType | undefined;
    submission: SubmissionType | undefined;
    card: CardType | undefined;
    onSuccess: () => void
}
const ReportSubmissionModal: React.FC<Props> = ({ showModal, onClose, currentUser, submission, card, onSuccess }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")
    const maxCharacterLimit = 300
    const minCharacterLimit = 10
    const sendReportSubmissionEmail = async () => {
        try {
            setLoading(true)
            var emailToBMI: EmailType = {
                subject: "New Submission Report",
                message: ReportSubmissionModalEmailToBMI(submission?.id!, submission?.posterEmail!, currentUser?.email!, card?.guid!, card?.paymentReleaseDeadline!),
                from: "buildmyidea.contact@gmail.com",
                to: "buildmyidea.contact@gmail.com",
                sendersName: "Build My Idea"
            }
            var emailToCustomer: EmailType = {
                subject: "Submission Report Received",
                message: ReportSubmissionModalEmailToCustomer(card?.jobTitle!),
                from: "buildmyidea.contact@gmail.com",
                to: currentUser?.email!,
                sendersName: "Build My Idea"
            }

            await sendEmail(emailToBMI)
            await sendEmail(emailToCustomer)
            card!.paymentReleaseDeadline = null
            await updateCard(card!)
            onSuccess()
            setMessage("")
            setLoading(false)
            onClose()
        } catch {
            setError(true)
            setLoading(false)
            setTimeout(() => setError(false), 3000)
        }
    }
    return (
        <>
            <Modal show={showModal} onHide={() => {
                setMessage("")
                onClose()
            }}>
                <Modal.Header closeButton closeVariant="white" style={{ backgroundColor: "var(--colour1)" }}>
                    <Modal.Title id={Styles.releasePaymentText}>Report Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body id={Styles.body}>
                    {error && <Alert severity="error">Something has gone wrong - please try again later</Alert>}
                    <p>Please write a short summary about why you are reporting this submission to help us with our investigation.</p>
                    <TextField
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        inputProps={{ maxLength: maxCharacterLimit, style: { color: "black" } }}
                        multiline
                        rows={5}
                        style={{ width: "100%", marginTop: "20px", backgroundColor: "white", borderRadius: "5px" }} />
                        {message.length < minCharacterLimit && <small style={{opacity: "0.7"}}>Min character limit of {minCharacterLimit}</small>}
                    <p style={{ float: "right", color: message.length >= maxCharacterLimit ? "red" : "white" }}>{message.length}/{maxCharacterLimit}</p>
                    <div id={Styles.buttonsWrapper} style={{ marginTop: "5%" }}>
                        <Button id={Styles.cancelButton} onClick={() => {
                            setMessage("")
                            onClose()
                        }}>Cancel</Button>
                        <Button id={Styles.releasePaymentButton} onClick={sendReportSubmissionEmail} style={{opacity: message.length < minCharacterLimit ? 0.5 : 1}} disabled={message.length < minCharacterLimit} >{loading ? <Spinner /> : <>Send Report</>}</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ReportSubmissionModal