import CurrentUserContext from "../../client/UserContextProvider"
import { deleteCommendation, updateCommendation } from "../../services/commendationService"
import { CommendationType } from "../../types/CommendationType"
import { useContext, useState } from "react"
import Styles from '../../css/profile/profile.module.css'
import DefaultProfilePicture from "../../images/default-profile-pic.png"
import { Col, Row, Spinner } from "react-bootstrap"
import "../../css/theme.css"

interface CommendationModalProps {
    setCommendations: React.Dispatch<React.SetStateAction<CommendationType[]>>;
    commendations: CommendationType[];
}
const Commendations: React.FC<CommendationModalProps> = ({ setCommendations, commendations }) => {
    const context = useContext(CurrentUserContext)
    const { currentUser } = context!;
    const [loading, setLoading] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const handleClick = async (commendation: CommendationType) => {
        const updatedCommendationsPromises = commendations.map(async (c) => {
            setLoading(true)
            if (c === commendation) {
                if (c.likes?.includes(currentUser?.id!)) {
                    c.likes = c.likes.filter((x) => x !== currentUser?.id);
                } else {
                    c.likes!.push(currentUser?.id!);
                }
                await updateCommendation(c);
            }
            setLoading(false)
            return c;
        });
        const updatedCommendations = await Promise.all(updatedCommendationsPromises);
        setCommendations(updatedCommendations);
    }
    const handleDelete = async (commendationId: number) => {
        setLoadingDelete(true)
        await deleteCommendation(commendationId)
        setCommendations([...commendations.filter(x => x.id != commendationId)])
        setLoadingDelete(false)
    }
    return (<>
        {commendations.map((commendation, index) => {
            return (
                <div id={Styles.commendation} key={index}>
                    <div style={{ backgroundColor: "#464B56", width: "100%", padding: "0.5% 2% 2% 2%" }}>
                        <Row>
                            <Col xs={0} md={3}>
                                <img id={Styles.commendationImage} src={commendation.poster?.profilePicture ? "data:image/png;base64, " + commendation.poster?.profilePicture : DefaultProfilePicture} alt="Profile" />
                            </Col>
                            <Col xs={12} md={9}>
                                <p id={Styles.commendationUsername}>{commendation.poster?.firstName} {commendation.poster?.lastName}</p>
                                <p id={Styles.commendationMessage}>{commendation.commendationMessage}</p>
                                <p id={Styles.commendationAgrees}>{commendation.likes?.length == 1 ? <>{commendation.likes?.length} Person Agree's</> : <>{commendation.likes?.length} People Agree</>}</p>
                                {commendation.posterId == currentUser?.id && <p id={Styles.commendationDeleteButton} onClick={async () => await handleDelete(commendation.id!)}>{loadingDelete ? <Spinner /> : <>Delete</>}</p>}
                                <p id={Styles.commendationAgreeButton} onClick={() => { handleClick(commendation) }}>{loading ? <Spinner /> : commendation.likes?.includes(currentUser?.id!) ? <>Un-Agree</> : <>Agree</>}</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        })}
    </>)
}
export default Commendations