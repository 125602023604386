import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Modal, Button, Form, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import CurrentUserContext from '../../client/UserContextProvider';
import { EmailType } from '../../types/EmailType';
import { sendEmail } from '../../services/emailService';
import { createSubmission } from '../../services/submissionService';
import { PaymentStateEnum, SubmissionType } from '../../types/SubmissionType';
import { UserType } from '../../types/UserType';
import { CardType } from '../../types/CardType';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GoogleIcon from '@mui/icons-material/Google';
import Styles from '../../css/job/emailModal.module.css'
import { submissionFormText } from '../../Text/emails';
import "../../css/theme.css"

interface SubmissionModalProps {
    showModal: boolean;
    onClose: () => void;
    posterDetails: UserType;
    cardDetails: CardType;
    setSubmissionSuccess: Dispatch<SetStateAction<boolean>>;
}
const SubmissionModal: React.FC<SubmissionModalProps> = ({ showModal, onClose, posterDetails, cardDetails, setSubmissionSuccess }) => {
    const GoogleColour = "#34a853"
    const GitHubColour= "purple"
    const YouTubeColour = "#FF0000"
    
    const [link, setLink] = useState("")
    const [message, setMessage] = useState("")
    const [showGithubInput, setShowGithubInput] = useState(false)
    const [showYouTubeInput, setShowYouTubeInput] = useState(false)
    const [showGoogleInput, setShowGoogleInput] = useState(false)
    const [githubUsername, setGithubUsername] = useState("")
    const [githubRepoName, setGithubRepoName] = useState("")
    const [loading, setLoading] = useState(false)
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    const youtubeBaseUrl = "https://www.youtube.com/watch/"
    const httpsUrl = "https://"
    const githubBaseUrl = ".github.io/"
    const googleDriveBaseUrl = "https://drive.google.com/drive/folders/"
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        var url;
        if (link == "" && githubUsername != "" && githubRepoName != "") {
            url = httpsUrl + githubUsername + githubBaseUrl + githubRepoName
        } else if (link != "") {
            url = `${determineBaseUrlValue()}${link!}`
        } else {
            return
        }
        const emailData: EmailType = {
            from: currentUser?.email!,
            to: posterDetails.email!,
            message: submissionFormText(posterDetails.firstName!, currentUser?.firstName!, message, url, cardDetails.jobTitle),
            subject: `New Submission Received for '${cardDetails.jobTitle}'`,
            sendersName: currentUser?.firstName!
        }
        var submission: SubmissionType = {
            cardId: cardDetails.id!,
            link: url,
            userId: currentUser?.id!,
            isAccepted: false,
            isRejected: false,
            cardTitle: cardDetails.jobTitle,
            paymentState: PaymentStateEnum.Unpaid,
            cardGuid: cardDetails.guid!,
            changeRequestsRemaining: 3,
            posterEmail: currentUser?.email!
        }

        await createSubmission(submission)
        cardDetails.submissions.push(submission)
        setLink("")
        setMessage("")
        await sendEmail(emailData);
        onClose()
        setLoading(false)
        setSubmissionSuccess(true)
    };
    const determineBaseUrlValue = () => {
        return showYouTubeInput ? youtubeBaseUrl : showGoogleInput ? googleDriveBaseUrl : ""
    }
    const clearInputs = () => {
        setGithubRepoName("")
        setGithubUsername("")
        setLink("")
    }
    return (
        <Modal show={showModal} onHide={() => {
            setLink("")
            setMessage("")
            onClose()
        }}>
            <Modal.Header id={Styles.modalHeader} closeButton>
                <Modal.Title>Send A Message to Poster<br /><small style={{ fontSize: "small" }}>Poster will see your email and firstname</small></Modal.Title>

            </Modal.Header>
            <Modal.Body id={Styles.modalBody}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="recipientEmail">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            value={cardDetails?.jobTitle}
                            disabled
                        />
                        <Form.Label>Project Link</Form.Label>
                        <br />
                        <div id={Styles.iconWrapper}>
                            <GoogleIcon className={Styles.icon} style={{ color: showGoogleInput ? GoogleColour : "" }} onClick={() => {
                                setShowGoogleInput(!showGoogleInput)
                                setShowGithubInput(false)
                                setShowYouTubeInput(false)
                                clearInputs()
                            }} />
                            <GitHubIcon className={Styles.icon} style={{ color: showGithubInput ? GitHubColour : "" }} onClick={() => {
                                setShowGithubInput(!showGithubInput)
                                setShowYouTubeInput(false)
                                setShowGoogleInput(false)
                                clearInputs()
                            }} />
                            <YouTubeIcon className={Styles.icon} style={{ color: showYouTubeInput ? YouTubeColour : "" }} onClick={() => {
                                setShowYouTubeInput(!showYouTubeInput)
                                setShowGoogleInput(false)
                                setShowGithubInput(false)
                                clearInputs()
                            }} />
                        </div>
                        <div style={{ backgroundColor: "var(--colour6)", margin: "1.5%", borderRadius: "10px", padding: "2px" }}><small>Select one of the icons and complete the URL - this should be either a Youtube video, a GitHub Pages page, or a Google Drive link to a video of the site.</small></div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {!showGithubInput && <InputGroup>
                                <InputGroup.Text id={Styles.baseUrl}>
                                    {determineBaseUrlValue()}
                                </InputGroup.Text>
                                <Form.Control
                                    value={link}
                                    onChange={(e) => {
                                        setLink(e.target.value)
                                    }}
                                    placeholder={(!showGithubInput && !showYouTubeInput && !showGoogleInput) ? "Select an icon from above" : ""}
                                    disabled={(!showGithubInput && !showYouTubeInput && !showGoogleInput)}
                                />
                            </InputGroup>}
                            {showGithubInput && <InputGroup>
                                <InputGroup.Text id={Styles.baseUrl}>
                                    {httpsUrl}
                                </InputGroup.Text>
                                <Form.Control
                                    value={githubUsername}
                                    onChange={(e) => {
                                        setGithubUsername(e.target.value)
                                    }}
                                    placeholder={"github username"}
                                />
                                <InputGroup.Text id={Styles.baseUrl}>
                                    {githubBaseUrl}
                                </InputGroup.Text>
                                <Form.Control
                                    value={githubRepoName}
                                    onChange={(e) => {
                                        setGithubRepoName(e.target.value)
                                    }}
                                    placeholder={"repo name"}
                                />
                            </InputGroup>}
                        </div>
                        {!showGithubInput && <small>Your fully qualified link: <span style={{ color: "red" }}>{determineBaseUrlValue()}{link}</span></small>}
                        {showGithubInput && <small>Your fully qualified link: <span style={{ color: "red" }}>{httpsUrl + githubUsername + githubBaseUrl + githubRepoName}</span></small>}
                        <br />
                        <Form.Label>Message</Form.Label>
                        <br />
                        <div style={{ backgroundColor: "var(--colour6)", margin: "1.5%", borderRadius: "10px", padding: "2px" }}><small>Add any other information that you want to include about your project</small></div>

                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={message}
                            onChange={(e) => {
                                if (message.length < 500) {
                                    setMessage(e.target.value)
                                }
                            }
                            }
                        />
                        <p>{message.length}/500</p>
                    </Form.Group>
                    <Button type="submit" id={Styles.sendButton} disabled={message.length == 0 || (link.length == 0 && (githubUsername.length == 0 || githubRepoName.length == 0))}>{loading ? <Spinner /> : <>Send</>}</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SubmissionModal;
