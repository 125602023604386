import Styles from "../../css/profile/submissions.module.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';
import { uploadSubmission } from '../../services/submissionService';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Alert, Button } from "@mui/material";
import "../../css/theme.css"

interface FileUploadProps {
    cardGuid: string;
    setFiles: (files: string[]) => void;
    files: string[];
    setUploadStatus: (status: string[]) => void;
    setFilesToUpload: (files: File[]) => void;
    filesToUpload: File[];
    notifyCustomer: () => Promise<boolean>;
}
const DragAndDropUpload: React.FC<FileUploadProps> = ({ cardGuid, setFiles, files, setUploadStatus, setFilesToUpload, filesToUpload, notifyCustomer }) => {
    const [loadingState, setLoadingState] = useState(0)
    const [notifyLoading, setNotifyLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [disableButtons, setDisableButtons] = useState(false)
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [emailFailure, setEmailFailure] = useState(false)
    const [lastCheckedTime, setLastCheckedTime] = useState(0);
    const [cooldownTime, setCooldownTime] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setLoadingState(prevState => (prevState < 3 ? prevState + 1 : 0));
        }, 700);
        return () => clearInterval(interval);
    }, [loading, notifyLoading])

    useEffect(() => {
        var cooldownTime = localStorage.getItem("_notifyCooldown" + cardGuid)
        if (cooldownTime != null) {
            const intervalId = setInterval(() => {
                const now = Date.now();
                const lastChecked = lastCheckedTime;
                if (now - lastChecked >= 60000) {
                    setCooldownTime(Number(checkNextNotifyTime()));
                    setLastCheckedTime(now);
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }

    }, [lastCheckedTime]);
    useEffect(() => {
        setCooldownTime(Number(checkNextNotifyTime()));
        setLastCheckedTime(Date.now());
    }, [files])

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const newFiles = [...filesToUpload, ...acceptedFiles];
        setFilesToUpload(newFiles)
    }, [filesToUpload, setFilesToUpload]);
    const clearUploadFiles = () => {


        setFilesToUpload([])
    }
    const sendUploadFiles = async () => {
        setLoading(true)
        setDisableButtons(true)
        const formData = new FormData();
        filesToUpload.forEach(file => {
            formData.append('files', file, file.name);
        });
        formData.append("cardGuid", cardGuid)
        try {
            await uploadSubmission(formData);
            setUploadStatus(filesToUpload.map(file => 'completed'));
            localStorage.setItem("_notifyCooldown" + cardGuid, new Date().toISOString())
            setFiles(files.concat(filesToUpload.filter(x => !files.includes(x.name)).map(x => x.name)))
            setTimeout(() => {
                setFilesToUpload([])
                setDisableButtons(false)
                setUploadStatus(filesToUpload.map(file => 'pending'));
            }, 3000)
        } catch (error) {
            setUploadStatus(filesToUpload.map(file => 'failed'));
            console.error('Upload failed:', error);
        }
        setLoading(false)
    };

    const handleNotifyUser = async () => {
        setNotifyLoading(true)
        try {
            var success: boolean = await notifyCustomer()
            if (success) {
                setEmailSuccess(true)
                setLastCheckedTime(Date.now() - 60000)
                setTimeout(() => setEmailSuccess(false), 3000)
                localStorage.setItem("_notifyCooldown" + cardGuid, new Date(new Date().getTime() + 30 * 60000).toISOString())
            } else {
                setEmailFailure(true)
                setTimeout(() => setEmailFailure(false), 3000)
            }
        } catch {

        }
        setNotifyLoading(false)
    }
    const checkNextNotifyTime = () => {
        var cooldownString = localStorage.getItem("_notifyCooldown" + cardGuid);
        if (cooldownString != null) {
            var cooldownDate = new Date(cooldownString);
            var currentTime = new Date();

            var differenceInMillis = cooldownDate.getTime() - currentTime.getTime();

            var differenceInMinutes = differenceInMillis / (1000 * 60);

            return differenceInMinutes.toFixed(0);
        }
        return 0;
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div id={Styles.fileUploadComponentWrapper}>
            {emailSuccess && <Alert style={{ marginBottom: "5px" }} severity="success">Email sent successfully</Alert>}
            {emailFailure && <Alert style={{ marginBottom: "5px" }} severity="error">Email failed to send</Alert>}
            <div {...getRootProps()} id={Styles.dropzone}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag and drop, or click to select files</p>
                }
            </div>
            <Button onClick={async () => {
                await handleNotifyUser()
            }} id={Styles.notifyCustomerButton}
                style={{ opacity: (files != null && files.length == 0) || disableButtons || cooldownTime > 0 ? "0.6" : "1" }}
                disabled={(files != null && files.length == 0) || disableButtons || cooldownTime > 0}>
                {notifyLoading ? `Notifying${'.'.repeat(loadingState)}` : <>Notify Customer {cooldownTime > 0 ? <><br />{`(${cooldownTime} mins)`}</> : ""}</>}
            </Button>
            <Button onClick={sendUploadFiles} id={Styles.uploadButton} style={{ opacity: filesToUpload.length == 0 || disableButtons ? "0.6" : "1" }} disabled={filesToUpload.length == 0 || disableButtons}>{loading ? `Uploading${'.'.repeat(loadingState)}` : "Upload Files"}</Button>
            <Button onClick={clearUploadFiles} id={Styles.removePendingUploadsButton} style={{ opacity: filesToUpload.length == 0 || disableButtons ? "0.6" : "1" }} disabled={filesToUpload.length == 0 || disableButtons}>Clear All</Button>

        </div>
    );
};

export default DragAndDropUpload;


