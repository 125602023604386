const landingPageText: LandingPageType = {
    "customer": {
        "tagLine": "Professional websites without breaking the bank",
        "sellPoint": [
            {
                "point": "Risk Free",
                "desc": "Get a refund right up until you find your perfect website"
            },
            {
                "point": "Hands Free",
                "desc": "Don't know what to do with website files? Don't worry! We're happy to handle all the hard stuff so you can focus on what's important - your business!"
            },
            {
                "point": "Control",
                "desc": "From start to finish you are in complete control - pick from multiple options submitted by developers. Don’t like any of them? No worries! Cancel your job card and get a refund*"
            }
        ],
        "steps": {
            "step1": {
                "heading": "Post",
                "value": "Create a job card describing the website that you want created. Do you want an eclectic pizza shop site? A warm inviting salon site? Just write it down and wait! Simple as that",
            },
            "step2": {
                "heading": "Review",
                "value": "Developers from all experience levels will see your job posting and take on the challenge. Upon completion of your site they will submit a demonstration site/video for you to view. Like their work? Approve it and move to the final phase - receiving the website.",
            },
            "step3": {
                "heading": "Profit",
                "value": "The developer will submit the files for you to view. If you are happy with their work, at this point we release payment to the developer for their hard work and you receive your shiny new site! Not sure what to do with the files? Leave that to us with our hosting options to make your life as easy as possible!",
            },
        },
        "finePrint" : "*Less a service fee"
    },
    "developer": {
        "tagLine": "Create something awesome - and get paid",
        "sellPoint": [
            {
                "point": "New Ideas",
                "desc": "Create something more than just another calculator to show employers - build something that’s going to used by REAL people - and get paid for it!"
            },
            {
                "point": "Get Paid",
                "desc": "What you see is what you get - 100% of bounty prices goes to you as the developer if your work is chosen by the customer."
            },
            {
                "point": "Assurance",
                "desc": "As soon as your work has been selected by the customer - the money is in the bag. Build-My-Idea holds onto all bounties and will pay out when the customer has received acceptable work."
            }
        ],
        "steps": {
            "step1": {
                "heading": "Search",
                "value": "Find a job card that you believe you can complete for the amount of money you think is fair and begin work!",
            },
            "step2": {
                "heading": "Submit",
                "value": "Once you have completed the site, submit a video or demo site to the customer for review. The customer will be notified upon submission and if they like what you’ve done, they will approve your submission!",
            },
            "step3": {
                "heading": "Profit",
                "value": "Once your work has been accepted and you have submitted your work - its up to the customer to review and approve that they have received the work they were expecting. Customers have 3 change requests they can submit and after that you will receive the money regardless*",
            }
        },
        "finePrint" : "*Unless work submitted is deceptive or clearly malicious in its form"
    }
}
export type LandingPageType = {
    customer: LandingPageVariationType,
    developer: LandingPageVariationType,
}
export type LandingPageVariationType = {
    tagLine: string,
    sellPoint: SellPoint[],
    steps: Steps,
    finePrint: string
}
export type SellPoint = {
    point: string,
    desc: string
}
export type Steps = {
    step1: Step,
    step2: Step,
    step3: Step
}
export type Step = {
    heading: string,
    value: string
}
export default landingPageText