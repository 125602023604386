import React, { useState } from 'react';
import { SignUpDetailsFormData } from './SignUpDetailsForm';
import { Button, Chip } from '@mui/material';
import { StyledTextField } from '../shared/styledTextField';
import Styles from '../../css/sign-up/signUpForm.module.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { Form } from 'react-bootstrap';
import { bioDescriptionMaxChars } from '../../Utils/valueSettings';
import "../../css/theme.css"

interface ChildProps {
    handleChange: (name: string, value: string) => void;
    formData: SignUpDetailsFormData;
    setFormData: React.Dispatch<React.SetStateAction<SignUpDetailsFormData>>;
}

const SignUpTextFields: React.FC<ChildProps> = ({ handleChange, formData, setFormData }) => {
    let [skillError, setSkillError] = useState(false)
    
    const [charsAmount, setCharsAmount] = useState<number>(0);

    const addSkill = () => {
        if (formData.skills.length < 5) {
            if (formData.skill.trim() !== "" && !formData.skills.includes(formData.skill)) {
                setFormData((prevData) => ({
                    ...prevData,
                    skills: [...prevData.skills, prevData.skill],
                    skill: "",
                }));
            }
        } else {
            setSkillError(true);
            setTimeout(() => setSkillError(false), 3000);
        }
    }
    const removeSkill = (index: number) => {
        const newData = [...formData.skills];
        newData.splice(index, 1);
        setFormData((prevData) => ({
            ...prevData,
            skills: newData,
        }));
    }
    return (<>
        {/* Profession */}
        <StyledTextField fullWidth className={Styles.signUpInput} id="standard-basic" inputProps={{ maxLength: 30 }}
            label="Profession" variant="standard" name="jobTitle" onChange={(e) => handleChange(e.target.name, e.target.value)} value={formData.jobTitle} />
        <div className={Styles.seperator} />
        {/* Location */}
        <StyledTextField fullWidth id="standard-basic" label="Location" variant="standard" inputProps={{ maxLength: 30 }}
            name="location" onChange={(e) => handleChange(e.target.name, e.target.value)} value={formData.location} />
        <div className={Styles.seperator} />
        {/* Description */}
        <p style={{ color: "#999" }}>Bio</p>
        <StyledTextField id={Styles.descriptionInput} rows={3} as="textarea" inputProps={{ maxLength: bioDescriptionMaxChars }}
            name="description" 
            onChange={(e) => {
                handleChange(e.target.name, e.target.value)
                setCharsAmount(e.target.value.length)
            }}
            value={formData.description} 
            placeholder='Hi, my name is...' />
        {charsAmount > 0 && <p style={{ float: "right", color: charsAmount > bioDescriptionMaxChars ? "red" : "white" }}>{charsAmount}/{bioDescriptionMaxChars}</p>}
        <div className={Styles.seperator} />
        {/* Skills */}
        {formData.skills.length > 0 && formData.skills.map((element, index) => {
            return <><Chip key={index} className={Styles.skillChip} label={element} deleteIcon={<CancelIcon />} onDelete={(e) => { removeSkill(index) }} /></>
        })}
        <Form onSubmit={(e) => {
            e.preventDefault()
            addSkill()
        }}>
            <div className={Styles.seperator} />
            <StyledTextField fullWidth error={skillError} helperText={skillError ? "Skill limit reached" : ""} inputProps={{ maxLength: 30 }}
                id="standard-basic" label="Skills" variant="standard" value={formData.skill}
                name="skill" onChange={(e) => handleChange(e.target.name, e.target.value)} />
            <Button type="submit" id={Styles.addSkillButton} >add skill</Button>
            {formData.skills.length > 0 && <p style={{ color: formData.skills.length == 5 ? "red" : "#999", float: "right" }}>{formData.skills.length} / 5</p>}
        </Form>
    </>
    );
};

export default SignUpTextFields;
