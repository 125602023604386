import { SubmissionType } from "../types/SubmissionType";
import axios from "axios";
import { SetAuthToken } from "./helperService";
import { config } from "../Utils/baseUrl";

const baseUrl = `${config.url.API_URL}/submissions`;
export async function createSubmission(submission: SubmissionType) {
    SetAuthToken(axios)
    await axios.post(baseUrl, submission, { withCredentials: true }).catch((e) => console.log(e))
  }
  export async function acceptSubmission(submission: SubmissionType) {
    SetAuthToken(axios)
    await axios.post(baseUrl + '/accept', submission, { withCredentials: true }).catch((e) => console.log(e))
  }
  export async function rejectSubmission(submission: SubmissionType) {
    SetAuthToken(axios)
    await axios.post(baseUrl + '/reject', submission, { withCredentials: true }).catch((e) => console.log(e))
  }
  export async function getSubmissions(userId: number): Promise<SubmissionType[]> {
    SetAuthToken(axios)
    return await axios.get(baseUrl + `/get-submissions/${userId}`, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
  }
  export async function getSubmissionByCardGuid(cardGuid: string): Promise<SubmissionType> {
    SetAuthToken(axios)
    return await axios.get(baseUrl + `/get-submission/${cardGuid}`, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
  }
  export async function updateSubmission(submission: SubmissionType): Promise<boolean> {
    SetAuthToken(axios)
    return await axios.patch(baseUrl + `/update-submission`, submission ,{ withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
  }
  export async function uploadSubmission(request: FormData): Promise<SubmissionType[]> {
    SetAuthToken(axios)
    return await axios.post(baseUrl + `/upload`, request, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
  }
  export async function listFiles(cardGuid: string): Promise<any> {
    SetAuthToken(axios)
    return await axios.get(baseUrl + `/get-folder-contents?cardGuid=${cardGuid}`, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
  }
  export async function getDownloadLinkForZipFile(cardGuid: string, fileNames: string[]): Promise<string | void> {
    SetAuthToken(axios)
    return await axios.post(baseUrl + `/get-download-link-for-zip?cardGuid=${cardGuid}`,fileNames, { responseType: 'blob', withCredentials: true }).then(resp => URL.createObjectURL(new Blob([resp.data]))).catch((e) => console.log(e))
  }
  export async function getDownloadLink(cardGuid: string, fileName: string): Promise<string> {
    SetAuthToken(axios)
    return await axios.get(baseUrl + `/get-download-link?cardGuid=${cardGuid}&fileName=${fileName}`, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
  }
  export async function deleteFile(cardGuid: string, fileNames: string[], deletefolder: boolean): Promise<boolean> {
    SetAuthToken(axios)
    return await axios.post(baseUrl + `/delete-files?cardGuid=${cardGuid}&deleteFolder=${deletefolder}`, fileNames, { withCredentials: true }).then(resp => resp.data).catch((e) => console.log(e))
  }
