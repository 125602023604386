import * as React from 'react';
import { Carousel } from 'react-bootstrap';
import DefaultJobCardImage from "../../images/defaultJobCardImage.png";
import "../../css/theme.css"


interface props {
    images: string[]
}

const ImageCarousel: React.FC<props> = ({ images }) => {
    return (
        <>
            {images.length == 0 && <img src={DefaultJobCardImage} style={{ width: "400px", height: "400px", objectFit: "contain" }}/>}
            {images.length > 0 && <Carousel style={{ marginTop: "5%" }}>
                {images.map((image, index) => {

                    return <Carousel.Item key={index}>
                        <img src={"data:image/png;base64," + image} style={{ width: "400px", height: "400px", objectFit: "contain" }} />
                    </Carousel.Item>
                })}
            </Carousel>}
        </>

    );
}
export default ImageCarousel

