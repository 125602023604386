import { FilterType } from "../../types/FilterType"
import { TechnologyTags, WebFrameworkTags } from "../../types/TechnologyTagsEnum"
import { AccordionSummary, Typography, AccordionDetails, Chip, Accordion, Button } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import Styles from '../../css/create-job/index.module.css'
import { Form } from "react-bootstrap";
import "../../css/theme.css"
interface TagSelectorProps {
    setTags: (newValue: FilterType[]) => void;
    tags: FilterType[];
    customTags: string[];
    setCustomTags: (newValue: string[]) => void;
}
const TagSelector:React.FC<TagSelectorProps> = ({setTags, tags, customTags, setCustomTags}) => {

    const [customTag, setCustomTag] = useState("")
    const addTag = (index: number, choice: Object) => {
        if (tags.length + customTags.length < 7) {
            var i: FilterType = {
                Type: choice,
                Index: index
            }
            var newFilterList = [...tags, i]
            setTags(newFilterList)
        }
    }
    const addCustomTag = () => {
        if (customTags.length + tags.length < 7 && customTag != "" && !customTags.find((x) => x == customTag)) {
            setCustomTags([...customTags, customTag])
            setCustomTag("")
        }
    }
    
    
    const checkIsSelected = (element: string) => {
        return tags.some((tag) => Object.keys(tag.Type)[tag.Index] === element)
    }
    return (
        <><Accordion style={{ backgroundColor: "var(--colour1)" }} >
            <AccordionSummary
                expandIcon={<AddIcon style={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography style={{ color: "white" }}>Web Technologies</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    <div className={Styles.technologyGroup}>
                        {Object.keys(TechnologyTags).map((element, index) => {
                            if (index % 3 == 0 && index != 0) {
                                return <><Chip key={index}
                                    onClick={() => addTag(index, TechnologyTags)}
                                    label={element}
                                    disabled={checkIsSelected(element)}
                                    style={{ backgroundColor: Object.values(TechnologyTags)[index], cursor: "pointer", margin: "0.5%", color: "black"}} /><br /></>
                            }
                            return <Chip key={index}
                                onClick={() => addTag(index, TechnologyTags)}
                                label={element}
                                disabled={checkIsSelected(element)}
                                style={{ backgroundColor: Object.values(TechnologyTags)[index], cursor: "pointer", margin: "0.5%", color: "black" }} />
                        })}
                    </div>
                </Typography>
            </AccordionDetails>
        </Accordion>
            <Accordion style={{ backgroundColor: "var(--colour1)" }}>
                <AccordionSummary
                    expandIcon={<AddIcon style={{ color: "white" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography style={{ color: "white" }}>Web Frameworks</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className={Styles.technologyGroup}>
                            {Object.keys(WebFrameworkTags).map((element, index) => {
                                if (index % 3 == 0 && index != 0) {
                                    return <><Chip key={index}
                                        onClick={() => addTag(index, WebFrameworkTags)}
                                        label={element}
                                        disabled={checkIsSelected(element)}
                                        style={{ backgroundColor: Object.values(WebFrameworkTags)[index], cursor: "pointer", margin: "0.5%", color: "black" }} /><br /></>
                                }
                                return <Chip key={index}
                                    onClick={() => addTag(index, WebFrameworkTags)}
                                    label={element}
                                    disabled={checkIsSelected(element)}
                                    style={{ backgroundColor: Object.values(WebFrameworkTags)[index], cursor: "pointer", margin: "0.5%", color: "black" }} />
                            })}
                        </div>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion style={{ backgroundColor: "var(--colour1)" }}>
                                <AccordionSummary
                                    expandIcon={<AddIcon style={{ color: "white" }} />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography style={{ color: "white" }}>Add A Custom Tag</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault()
                                            addCustomTag()
                                        }}>
                                            <input placeholder='add a custom tag' value={customTag} onChange={(e) => setCustomTag(e.target.value)}/>
                                        <Button type="submit" style={{backgroundColor: "var(--colour2)", color: "white", marginLeft: "5%"}}>Add</Button>
                                        </Form>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
        </>
    )
}

export default TagSelector