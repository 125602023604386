import JobCard from '../components/home/jobCard';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Styles from '../css/home/page.module.css';
import SearchBar from '../components/home/searchBar';
import CircularProgress from '@mui/material/CircularProgress';
import BugReportModal from '../components/home/bugReportModal';
import FilterBox from '../components/shared/FilterBox';
import CreateJobModal from '../components/create-job/createJobModal';
import { Button, Col } from 'react-bootstrap';
import { FilterType } from '../types/FilterType';
import { getCards, getMaxBounty } from '../services/cardService';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { useEffect, useState } from "react";
import { CardType } from '../types/CardType';
import { useLocation, useNavigate } from "react-router-dom";
import FilterModal from '../components/home/filterModal';
import { Chip } from '@mui/material';
import ReactGA from 'react-ga4';
import "../css/theme.css"

const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const openCreateJobModal = queryParams.get('openModal');
  const [cards, setCards] = useState<CardType[]>([]);
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState<string>("Sort By")
  const [filterTags, setFilterTags] = useState<string[]>([])
  const [query, setQuery] = useState<string>("")
  const [showBugReportModal, setShowBugReportModal] = useState(false)
  const [filters, setFilters] = useState<FilterType[]>([])
  const [maxBounty, setMaxBounty] = useState(0)
  const [range, setRange] = useState("")
  const [showCreateJobModal, setShowCreateJobModal] = useState(openCreateJobModal == "true")
  const [showFilterModal, setShowFilterModal] = useState(false)
  var maxCardsLoaded = false;



  useEffect(() => {
    setLoading(true)
    setCards([])
    setPage(0)
    retrieveCards(0)
    retrieveMaxBounty()
    ReactGA.send({
      hitType: "pageview",
      page: "/home",
      title: "Home",
    })
  }, [sortBy, filterTags, query, range]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight && !maxCardsLoaded
      ) {
        retrieveCards(page);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page]);

  const retrieveMaxBounty = async () => {
    setMaxBounty(await getMaxBounty())
  }
  const retrieveCards = async (page: number, num = 12) => {
    let cardsList = await getCards(page, num, query, sortBy, filterTags, range);
    setCards((prevItems) => [...prevItems, ...cardsList]);
    setLoading(false)
    if (cardsList.length !== num) {
      maxCardsLoaded = true
      return
    }
    setPage((prevPage) => prevPage + 1);
  };
  const executeSearch = () => {
    setLoading(true)
    setCards([])
    setPage(0)
    retrieveCards(0);
  }
  const onCloseBugReportModal = () => {
    setShowBugReportModal(false)
  }
  const onClose = () => {
    setShowCreateJobModal(false)
  }
  const filterOnClose = () => {
    setShowFilterModal(false)
  }
  return (
    <div id={Styles.homeWrapper}>
      <CreateJobModal showModal={showCreateJobModal} onClose={onClose} />
      <FilterModal
        setFilterTags={setFilterTags}
        filters={filters}
        setFilters={setFilters}
        maxBounty={maxBounty}
        setRange={setRange}
        onClose={filterOnClose}
        showModal={showFilterModal}
      />
      <div id={Styles.bugReportWrapper}>
        <p id={Styles.bugReportText}>You are currently viewing our Beta site. Found a bug or want to suggest a feature?<span id={Styles.submitItHereLink} onClick={() => setShowBugReportModal(true)}> Submit it here</span></p>
      </div>
      <Container>
        {showBugReportModal && <BugReportModal showModal={showBugReportModal} onClose={onCloseBugReportModal} />}
        <Row>
          <SearchBar setSortBy={setSortBy} sortBy={sortBy} setQuery={setQuery} query={query} executeSearch={executeSearch} setShowCreateJobModal={setShowCreateJobModal}
            filterButton={<Button id={Styles.filterButton} onClick={(e) => setShowFilterModal(true)}>{ }<FilterAltIcon /></Button>}
            setFilterTags={setFilterTags}
            setFilters={setFilters}
            setRange={setRange}
          />
          {filters.length > 0 && <div id={Styles.chipWrapper}>
            <p style={{ color: "white", display: "inline-block" }}>Tags: <span>
              {filters.map((filter, index) =>
                <Chip key={index}
                  label={Object.keys(filter.Type)[filter.Index]}
                  style={{ backgroundColor: Object.values(filter.Type)[filter.Index], cursor: "pointer", margin: "0.5%" }} />)}
            </span></p>
          </div>}
        </Row>
        <Row>
          <Col lg={0} xl={3}>
            <div id={Styles.filterBoxWrapper}>
              <FilterBox
                setFilterTags={setFilterTags}
                filters={filters}
                setFilters={setFilters}
                maxBounty={maxBounty}
                setRange={setRange}
              />
            </div>
          </Col>
          <Col lg={12} xl={9} className='align-items-center'>
            <div id={Styles.homescreenJobWrapper}>
              {!loading && cards.length > 0 && cards.map((card, index) =>
                <JobCard key={index} card={card} onClick={() => { navigate(`/job/${card.id}`) }} />
              )}
              {loading && <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <CircularProgress size={"6rem"} />
              </div>}
              {!loading && cards.length == 0 && <div style={{ width: "100%", display: "flex", justifyContent: "center", fontSize: "large", color: "white" }}>
                <p >No job cards were found</p></div>}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default HomePage