import { useEffect, useRef, useState } from "react"
import { TableHead, TableRow, TableBody, Avatar } from '@mui/material';
import { Table, TableContainer, TableCell } from '@mui/material';
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { GetUserCount, RetrieveUserInfoViaId, RetrieveUserRange, UpdateUserInfo } from "../services/userService";
import { UserType } from "../types/UserType";
import UserCard from "../components/admin/userCard";
import Styles from "../css/admin/index.module.css"
import UserInformation from "../components/admin/userTransactions";
import DefaultProfilePicture from '../images/default-profile-pic.png'
import "../css/theme.css"
const Admin = () => {
    const [users, setUsers] = useState<UserType[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
    const [userCount, setUserCount] = useState(0)
    const [maxPages, setMaxPages] = useState(0)
    const [selectedPage, setSelectedPage] = useState(1)
    const [selectedAmount, setSelectedAmount] = useState(15)
    useEffect(() => {
        getUserByPageAndRange();
        getCurrentUserCount();
    }, [selectedPage, selectedAmount]);

    const getCurrentUserCount = async () => {
        const userCount = await GetUserCount()
        setUserCount(userCount)
        setMaxPages(Math.ceil(userCount / selectedAmount))
    }
    const getUserByPageAndRange = async () => {
        const newUsersList = await RetrieveUserRange(selectedPage - 1, selectedAmount)
        setUsers(newUsersList);
    }

    const handleActivate = async () => {
        if (selectedUser) {
            const updatedUser = { ...selectedUser, isActivated: true };
            const response = await UpdateUserInfo(updatedUser);
            if (response.success) {
                setSelectedUser(updatedUser);
                setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
            }
        }
    };
    const handleDeactivate = async () => {
        if (selectedUser) {
            const updatedUser = { ...selectedUser, isActivated: false };
            const response = await UpdateUserInfo(updatedUser);
            if (response.success) {
                setSelectedUser(updatedUser);
                setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
            }
        }
    };

    const handleAdminButton = async () => {
        if (selectedUser) {
            const updatedUser = { ...selectedUser, userRole: selectedUser.userRole === 0 ? 1 : 0 }
            const response = await UpdateUserInfo(updatedUser);
            if (response.success) {
                setSelectedUser(updatedUser);
                setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
            }
        }
    };
    const searchCustomerId = async (id: number) => {
        var customer = await RetrieveUserInfoViaId(id)
        setUsers([customer])
    }
    return (
        <div id={Styles.wrapper}>
            <Container>
                <div>
                    <Row>
                        <h1 style={{ color: "var(--colour2)" }} className="text-center mb-3">Admin Dashboard</h1>
                        <p style={{ color: "white", textAlign: "center" }}>Current user count: {userCount}</p>
                        <div style={{ margin: "0 auto", width: "15%", display: "flex", justifyContent: "center", textAlign: "center" }}>
                            <p style={{ color: "white" }}>Users per page:</p>
                            <Dropdown style={{ margin: "0 auto" }}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {selectedAmount}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setSelectedAmount(5)}>5</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setSelectedAmount(10)}>10</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setSelectedAmount(15)}>15</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <p style={{ color: "white" }}>Search ID:</p><input onChange={async (e) => {
                                if (e.target.value != "") {
                                    searchCustomerId(parseInt(e.target.value))
                                } else {
                                    getUserByPageAndRange()
                                }
                            }} style={{ marginLeft: "10px", height: "30px", width: "50px" }} />
                        </div>
                    </Row>
                    <Row className="mx-5">
                        <TableContainer>
                            <Table sx={{ backgroundColor: 'white' }}>
                                <TableHead sx={{ backgroundColor: '#808080' }}>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Id</TableCell>
                                        <TableCell>Username</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>Created At</TableCell>
                                        <TableCell>Activated</TableCell>
                                        <TableCell>Admin</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <TableRow key={user.id} onClick={() => setSelectedUser(user)} style={{ cursor: 'pointer' }}>
                                            <TableCell>
                                                <Avatar src={user.profilePicture ? "data:image/png;base64," + user.profilePicture : DefaultProfilePicture} alt="Profile" />
                                            </TableCell>
                                            <TableCell>{user.id}</TableCell>
                                            <TableCell>{user.username}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.firstName}</TableCell>
                                            <TableCell>{user.lastName}</TableCell>
                                            <TableCell>{user.accountCreationDate}</TableCell>
                                            <TableCell>{user.isActivated ? "Yes" : "No"}</TableCell>
                                            <TableCell>{user.userRole ? "Yes" : "No"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            {Array.from({ length: maxPages }, (_, index) => (
                                <span key={index}
                                    onClick={() => {
                                        setSelectedPage(index + 1)
                                    }}
                                    style={{
                                        color: "white", marginRight: "5px", cursor: "pointer",
                                        textDecoration: index + 1 == selectedPage ? "underline" : "none"
                                    }}>{index + 1}</span>
                            ))}
                        </div>
                    </Row>
                    <Row className="mx-5 mt-3">
                        <Col md={4} lg={3}>
                            {selectedUser && (
                                <UserCard
                                    user={selectedUser}
                                    onActivate={handleActivate}
                                    onDeactivate={handleDeactivate}
                                    onSetAdmin={handleAdminButton}
                                />
                            )}
                        </Col>
                        <Col md={8} lg={9}>
                            {selectedUser && (
                                <UserInformation
                                    user={selectedUser}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
};
export default Admin