import { Col, Container, Row } from "react-bootstrap"
import LoginForm from "../components/login/loginForm"
import Styles from '../css/login/index.module.css'
import {useEffect, useState } from "react"
import Logo from "../images/Logo.png";
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import { Alert } from "@mui/material";
import "../css/theme.css"
const LoginPage = () => {
    let [error, setError] = useState(false)
    let [accountDeletedMessage, setAccountDeletedMessage] = useState(false)
    let [oauthFail, setOauthFail] = useState(false)
    const location = useLocation()
    const { accountDeleted } = queryString.parse(location.search);
    useEffect(() => {
        if (accountDeleted === 'true') {
            setAccountDeletedMessage(true)
            setTimeout(() => { setAccountDeletedMessage(false) }, 5000)
        }
    }, [accountDeleted])
    const googleOAuthFail = () => {
        setOauthFail(true)
        setTimeout(() => { setOauthFail(false) }, 5000)
    }
    return (
        <div id={Styles.loginWrapper}>
        <Container>
            <div style={{display: "flex", justifyContent: "center"}}>
            {error && <Alert severity="error" style={{ position: "fixed", left: "40%", top: "10%", zIndex: "999" }}>Email or Password were incorrect</Alert>}
            {accountDeletedMessage && <Alert severity="success" style={{ position: "fixed", margin: "0 auto", top: "10%", zIndex: "999" }}>Account Deleted Successfully</Alert>}
            {oauthFail && <Alert severity='error' style={{ position: "fixed", margin: "0 auto", top: "10%", zIndex: "999" }}>OAuth login failed - please make sure you have popups allowed for this page</Alert>}
            </div>
            <Row>
                <Col xs={12} md={6}>
                    <LoginForm setError={setError} googleOAuthFail={googleOAuthFail}/>
                </Col>
                <Col xs={0} md={6}>
                    <img id={Styles.loginLogo} src={Logo} alt="Build-My-Idea Logo"/>
                </Col>
            </Row>
        </Container>
        </div>
    )
}
export default LoginPage