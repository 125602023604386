import CurrentUserContext from "../../client/UserContextProvider";
import { sendEmail } from "../../services/emailService";
import { bugReportText } from "../../Text/emails";
import { EmailType } from "../../types/EmailType";
import { useContext, useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import "../../css/theme.css"
interface BugReportProps {
    showModal: boolean;
    onClose: () => void;
}
const BugReportModal: React.FC<BugReportProps> = ({ showModal, onClose }) => {
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const maxChars = 1500
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const emailData: EmailType = {
            from: currentUser?.email!,
            to: 'buildmyidea.contact@gmail.com',
            message: bugReportText(message),
            subject: "A new Bug Report/Feature Suggestion is in",
            sendersName: currentUser?.firstName!
        }

        setLoading(true)

        await sendEmail(emailData);
        setMessage("")
        setLoading(false)
        setSuccessAlert(true)
        setTimeout(() => { setSuccessAlert(false) }, 4000)
    }


    return (
        <Modal show={showModal} onHide={() => {
            setMessage("")
            onClose()
        }}>
            <Modal.Header closeButton style={{ backgroundColor: "var(--colour1)", color: "white" }}>
                <Modal.Title>Submit your bug or feature idea and we will assess it as soon as possible!</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "var(--colour1)", color: "white" }}>
                {successAlert && <Alert variant="success">Thank you for your message - it will be assessed ASAP</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Label>Bug Report or Feature Suggestion:</Form.Label>
                    <br />
                    <textarea onChange={(e) => setMessage(e.target.value)} maxLength={maxChars} value={message} rows={5} style={{ width: "100%" }} />
                    <p style={{ textAlign: "right", color: message.length >= maxChars ? "red" : "white" }}>{message.length}/{maxChars}</p>
                    <Button type="submit" disabled={loading} style={{ background: "var(--colour2)", border: "none", float: "right", margin: "1%" }}>{loading ? <Spinner /> : <>Submit</>}</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default BugReportModal