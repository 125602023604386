import axios, { AxiosResponse } from "axios";
import { SetAuthToken } from "./helperService";
import { UserType } from "../types/UserType";
import { config } from "../Utils/baseUrl";
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from "@firebase/auth";
import auth from "./firebaseAuthService";
import { TokenSwapType } from "../types/TokenSwapType";
import { UserUpdateSuccessResponseType } from "../types/UserUpdateSuccessResponseType";
import { DeleteUser } from "./userService";
import { AccountActivationStatusType } from "../types/AccountActivationStatusType";

var baseUrl = `${config.url.API_URL}/auth`
export const CheckLoggedIn = () => {
  return axios.get(baseUrl + `/check-logged-in`, { withCredentials: true })
    .then(resp => true)
    .catch(e => false)
}
export const CheckAccountIsActivated = async (email: string): Promise<AccountActivationStatusType> => {
  return await axios.get(baseUrl + `/check-account-activated?email=${email}`)
    .then(async resp => {
      return resp.data
    })
    .catch(e => false)
}
export const LoginUser = async (email: string, password: string): Promise<TokenSwapType | null> => {
  try {
    //Log user in
    const userCredential = await signInWithEmailAndPassword(auth, email, password);

    const user = userCredential.user;
    if (user) {
      var response = await FireBaseTokenSwapRequest(await user.getIdToken(), user.email!)
      if (response.requestAccountActivation) {
        localStorage.setItem("email", email)
        auth.signOut()
      }
      return response
    }
    return null;
  } catch (error) {
    console.error('Login Error:', error);
    return null;
  }
};
export const GoogleOAuthTokenSwapRequest = async (googleAuthToken: string): Promise<TokenSwapType> => {
  try {
    axios.defaults.headers.common["Authorization"] = `Bearer ${googleAuthToken}`;
    var resp = (await axios.get(baseUrl + '/swap-google-oauth-token', { withCredentials: true })).data;
    localStorage.setItem("isOauthAcc", "true")
    return resp;
  } catch (err) {
    throw err
  }

}
export const FireBaseTokenSwapRequest = async (token: string, email: string): Promise<TokenSwapType> => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return (await axios.get(`${baseUrl}/swap-firebase-token?email=${email}`, { withCredentials: true })).data;
}

export const SignUp = async (email: string, password: string, username: string, firstName: string, lastName: string): Promise<UserUpdateSuccessResponseType> => {
  try {
    const resp: UserUpdateSuccessResponseType = await axios.post(baseUrl + '/sign-up', {
      password: password,
      username: username,
      email: email,
      firstName: firstName,
      lastName: lastName
    }).then(resp => resp.data);

    if (!resp.success) {
      return resp;
    }
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    if (userCredential) {
      return resp;
    }
    await DeleteUser(parseInt(resp.message), false)
    throw new Error("Failed saving user to Firebase")

  } catch (error) {
    console.error('Registration Error:', error);
    throw error;
  }
};
export const CheckUserPassword = async (email: string, password: string): Promise<AxiosResponse<any, any>> => {
  try {
    const resp = await axios.get(baseUrl + '/check-password', {
      headers: {
        Authorization: 'Basic ' + btoa(email + ':' + password)
      },
      withCredentials: true
    });
    return resp;
  } catch (err) {
    throw err
  }
};
export const UpdateUserPassword = async (user: UserType, password: string) => {
  SetAuthToken(axios)
  var updateUserPasswordRequest = {
    user: user,
    password: password
  }
  return await axios.post(baseUrl + "/update-user-password", updateUserPasswordRequest, { withCredentials: true });
}
export const LogoutUser = async () => {
  await auth.signOut().then(resp => {
    return true
  })
    .catch(err => false)
  await axios.post(baseUrl + "/logout")
}
export const ResetUserPassword = async (email: string): Promise<boolean> => {
  return await sendPasswordResetEmail(auth, email)
    .then(resp => {
      return true
    })
    .catch((error) => {
      return false
    })
}

export const ActivateUserAccount = async (token: string) => {
  return await axios.post(baseUrl + `/activate-account`, { token: token }).then(resp => true).catch((e) => false)
}