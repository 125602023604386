import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled, BadgeProps } from '@mui/material'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import GridViewIcon from '@mui/icons-material/GridView';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import GavelIcon from '@mui/icons-material/Gavel';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import CurrentUserContext from '../../client/UserContextProvider';
import { UserRoleType } from '../../types/UserRoleType';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import DefaultProfilePicture from '../../images/default-profile-pic.png'
import HomeIcon from '@mui/icons-material/Home';
import Styles from '../../css/shared/menu.module.css'
import "../../css/theme.css"

interface ChildProps {
    logout: () => Promise<void>;
    isGoogleAuthenticated: boolean;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        minWidth: '16px', 
        height: '16px', 
        fontSize: '10px',
        padding: '0 4px',
    },
  }));

const AccountMenu: React.FC<ChildProps> = ({ logout, isGoogleAuthenticated }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [userSubmissions, setUserSubmissions] = useState(0);
    const [userRequests, setUserRequests] = useState(0);

    const context = React.useContext(CurrentUserContext);
    const { currentUser } = context!;
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', margin: '0' }}>
                <Tooltip title="Menu">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {currentUser && <Avatar src={currentUser.profilePicture != null ? "data:image/png;base64," + currentUser.profilePicture : DefaultProfilePicture} sx={{ width: 54, height: 54 }} />}
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Link id={Styles.dropDownHomeMenuItem} to="/home">
                    <MenuItem style={{color: "black"}} onClick={handleClose}>
                        <ListItemIcon>
                            <HomeIcon fontSize="small" />
                        </ListItemIcon>
                        Home
                    </MenuItem>
                </Link>
                <Link to="/profile">
                    <MenuItem style={{color: "black"}} onClick={handleClose}>
                        <ListItemIcon>
                            <PersonIcon fontSize="small" />
                        </ListItemIcon>
                        Profile
                    </MenuItem>
                </Link>
                <Link to="/profile/dashboard">
                    <MenuItem style={{color: "black"}} onClick={handleClose}>
                        <ListItemIcon>
                            <GridViewIcon fontSize="small" />
                        </ListItemIcon>
                        Dashboard
                    </MenuItem>
                </Link>
                <Link to="/profile/dashboard/submissions">
                    <MenuItem style={{color: "black"}} onClick={handleClose}>
                        <ListItemIcon>
                            <StyledBadge color='error' badgeContent={userSubmissions}>
                                <DriveFolderUploadIcon fontSize="small" />
                            </StyledBadge>
                        </ListItemIcon>
                        Manage Submissions
                    </MenuItem>
                </Link>
                <Link to="/profile/dashboard/requests">
                    <MenuItem style={{color: "black"}} onClick={handleClose}>
                        <ListItemIcon>
                            <StyledBadge color='error' badgeContent={userRequests}>
                                <RequestPageIcon fontSize="small" />
                            </StyledBadge>
                        </ListItemIcon>
                        Manage Requests
                    </MenuItem>
                </Link>

                <Divider />
                <Link to="/settings">
                    <MenuItem style={{color: "black"}} onClick={handleClose}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                </Link>
                {currentUser?.userRole == UserRoleType.Admin && <>
                    <Link to="/admin">
                        <MenuItem style={{color: "black"}} onClick={handleClose}>
                            <ListItemIcon>
                                <GavelIcon fontSize="small" />
                            </ListItemIcon>
                            Admin
                        </MenuItem>
                    </Link>
                    <Divider />
                </>}
                {!isGoogleAuthenticated && <Link to="#" >
                    <MenuItem style={{color: "black"}} onClick={() => {
                        handleClose();
                        logout();
                    }}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Link>}
            </Menu>
        </React.Fragment>
    );
}
export default AccountMenu;