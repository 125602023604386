import { getDownloadLink, getDownloadLinkForZipFile, getSubmissionByCardGuid, listFiles } from "../services/submissionService";
import { AccordionDetails, AccordionSummary, Alert, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Accordion, Select, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Styles from "../css/profile/requests.module.css";
import CurrentUserContext from "../client/UserContextProvider";
import { Col, Container, Row } from "react-bootstrap";
import { getAllCardsForUser, updateCard } from "../services/cardService";
import { CardType } from "../types/CardType";
import RequestChangesModal from "../components/profile/RequestChangesModal";
import { sendEmail } from "../services/emailService";
import { EmailType } from "../types/EmailType";
import { SubmissionType } from "../types/SubmissionType";
import ReleasePaymentModal from "../components/profile/ReleasePaymentModal";
import ReportSubmissionModal from "../components/profile/ReportSubmissionModal";
import { ManageRequestsEmail } from "../Text/emails";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';
import "../css/theme.css"
const Requests = () => {
    const [files, setFiles] = useState<string[]>([]);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [checkedFiles, setCheckedFiles] = useState<string[]>([])
    const [masterChecked, setMasterChecked] = useState(false)
    const [displayRequestChangesModal, setDisplayRequestChangesModal] = useState(false)
    const [displayReleasePaymentModal, setDisplayReleasePaymentModal] = useState(false)
    const [displayReportSubmissionModal, setDisplayReportSubmissionModal] = useState(false)
    const [userCards, setUserCards] = useState<CardType[]>([])
    const [selectedCard, setSelectedCard] = useState<CardType | undefined>()
    const [selectedSubmission, setSelectedSubmission] = useState<SubmissionType | undefined>()
    const [loadingState, setLoadingState] = useState(0)
    const [changeRequestSubmitted, setChangeRequestSubmitted] = useState(false)
    const [releasePaymentRequestSubmitted, setReleasePaymentRequestSubmitted] = useState(false)
    const [releasePaymentDeadline, setReleasePaymentDeadline] = useState<(string | number)[]>([])
    const [submissionReportSuccessAlert, setSubmissionReportSuccessAlert] = useState(false)
    const [accordionExpand, setAccordionExpand] = useState(true)
    const context = useContext(CurrentUserContext);
    const { currentUser } = context!
    useEffect(() => {
        const fetchFiles = async () => {
            if (selectedCard!.acceptedSubmission) {
                const responseFiles = await listFiles(selectedCard!.guid!);
                setFiles(responseFiles);
            }
        };
        const fetchCards = async () => {
            var cards: CardType[] = await getAllCardsForUser(currentUser?.id!)
            setUserCards(cards)
        }
        if (selectedCard == null && currentUser != null) {
            fetchCards();
        }
        if (selectedCard != null) {
            fetchFiles();
            var differences = determineDayDifference()
            setReleasePaymentDeadline(differences)
        }
    }, [selectedCard, currentUser]);

    useEffect(() => {
        if (downloadLoading) {
            const interval = setInterval(() => {
                setLoadingState(prevState => (prevState < 3 ? prevState + 1 : 0));
            }, 500);
            return () => clearInterval(interval);
        }
    }, [downloadLoading])

    const retrieveAndOpenDownloadLink = async () => {
        setDownloadLoading(true)

        try {
            if (checkedFiles.length > 1) {
                const response = await getDownloadLinkForZipFile(selectedCard!.guid!, checkedFiles);
                if (response) {
                    const anchor = document.createElement('a');
                    anchor.href = response;
                    anchor.setAttribute('download', selectedCard!.jobTitle.replace(" ", "_") + ".zip");
                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);
                }
            } else {
                if (checkedFiles.length != 0) {
                    const response = await getDownloadLink(selectedCard!.guid!, checkedFiles[0]);
                    if (response) {
                        const anchor = document.createElement('a');
                        anchor.href = response;
                        anchor.setAttribute('download', '');
                        document.body.appendChild(anchor);
                        anchor.click();
                        document.body.removeChild(anchor);
                    }
                }
            }
        } catch (error) {
            console.error('Failed to download the file:', error);
        } finally {
            setDownloadLoading(false)
        }
    };

    const handleCheck = (isChecked: boolean, fileName: string) => {
        if (isChecked) {
            setMasterChecked(files.length == checkedFiles.length + 1 ? true : false)
            var newCheckedFiles = [...checkedFiles, fileName]
            setCheckedFiles(newCheckedFiles)
        } else {
            var newCheckedFiles = checkedFiles.filter(name => name != fileName)
            setCheckedFiles(newCheckedFiles)
            setMasterChecked(false)
        }
    }
    const handleMasterCheck = (isChecked: boolean) => {
        setMasterChecked(isChecked);
        if (isChecked) {
            setCheckedFiles(files);
        } else {
            setCheckedFiles([]);
        }
    };
    const handleSelectedCardChange = async (card: CardType) => {
        if (card.acceptedSubmission) {
            setSelectedCard(card)
            var submission = await getSubmissionByCardGuid(card.guid!)
            setSelectedSubmission(submission)
        }
    }
    const closeRequestChangesModal = () => {
        setDisplayRequestChangesModal(false)
    }
    const closeReportSubmissionModal = () => {
        setDisplayReportSubmissionModal(false)
    }
    const closeReleasePaymentModal = () => {
        setDisplayReleasePaymentModal(false)
    }
    const showSubmissionReportSuccessAlert = () => {
        setSubmissionReportSuccessAlert(true)
        setTimeout(() => setSubmissionReportSuccessAlert(false), 6000)
    }
    const handleSubmitChangeRequest = async (messageValue: string, from: string, to: string) => {
        var emailObject: EmailType = {
            subject: `Changes requested for '${selectedCard?.jobTitle}'`,
            message: ManageRequestsEmail(messageValue, selectedSubmission!),
            from: from,
            to: to,
            sendersName: `${currentUser?.firstName} ${currentUser?.lastName}`
        }
        await sendEmail(emailObject)
        selectedCard!.paymentReleaseDeadline = null
        await updateCard(selectedCard!)
        setChangeRequestSubmitted(true)
        setTimeout(() => setChangeRequestSubmitted(false), 3000)
    }
    const handleSubmitReleasePaymentRequest = () => {
        setReleasePaymentRequestSubmitted(true)
        setSelectedCard(undefined)
        setSelectedSubmission(undefined)
        setTimeout(() => setReleasePaymentRequestSubmitted(false), 3000)
    }
    const determineDayDifference = () => {
        var today = new Date()
        var deadline = new Date(selectedCard!.paymentReleaseDeadline!)
        var differenceInMilliseconds = deadline.getTime() - today.getTime()
        var hoursDifference = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60))
        if (hoursDifference <= 1) {
            return [Math.ceil(differenceInMilliseconds / (1000 * 60)), "minutes"]
        }
        if (hoursDifference <= 24) {
            return [hoursDifference, "hours"]
        }
        return [Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24)), "days"]
    }
    return (
        <div id={Styles.requestPageWrapper}>
            <RequestChangesModal onClose={closeRequestChangesModal} showModal={displayRequestChangesModal} currentUser={currentUser} handleSubmitChangeRequest={handleSubmitChangeRequest} submission={selectedSubmission} />
            <ReportSubmissionModal onClose={closeReportSubmissionModal} showModal={displayReportSubmissionModal} currentUser={currentUser} submission={selectedSubmission} card={selectedCard} onSuccess={showSubmissionReportSuccessAlert} />
            <ReleasePaymentModal onClose={closeReleasePaymentModal}
                showModal={displayReleasePaymentModal}
                currentUser={currentUser}
                submission={selectedSubmission}
                card={selectedCard}
                bounty={selectedCard?.jobBounty!}
                handleSubmitReleasePaymentRequest={handleSubmitReleasePaymentRequest}
                files={files} />

            {changeRequestSubmitted && <Alert severity="success" style={{ width: "40%", margin: "0 auto" }}>Change Request was successfully submitted</Alert>}
            {releasePaymentRequestSubmitted && <Alert severity="success" style={{ width: "40%", margin: "0 auto" }}>Release Payment request was successfully submitted</Alert>}
            {submissionReportSuccessAlert && <Alert severity="success" style={{ width: "40%", margin: "0 auto" }}>Submission has been reported - an email from Build-My-Idea has been sent to you with next steps</Alert>}
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <div id={Styles.leftColumn}>
                            <div id={Styles.displaySubmissionsWrapper}>
                                <FormControl fullWidth>
                                    <InputLabel id="request-selector-label" style={{ color: "white" }}>Card</InputLabel>
                                    <Select
                                        labelId="request-selector"
                                        id="request-selector"
                                        sx={{
                                            background: "var(--colour6)",
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'var(--colour1)',
                                                borderRadius: "0"
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: "var(--colour3)",
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: "var(--colour2)",
                                            }
                                        }}
                                        style={{ color: "white" }}
                                        value={selectedCard?.id ?? "default"}
                                        label="Card"
                                        onChange={async (event) => {
                                            const selectedCardId = event.target.value;
                                            const card = userCards.find(card => `${card.id}` == selectedCardId);
                                            if (card) {
                                                await handleSelectedCardChange(card);
                                            }
                                        }}
                                    >
                                        <MenuItem value={"default"}>Please select a job</MenuItem>;
                                        {userCards != null && userCards.length > 0 && userCards.map((card, index) => {
                                            if (card.acceptedSubmission && !card.isArchived) {
                                                return <MenuItem key={index} value={card.id?.toString()}>{card.jobTitle}</MenuItem>;
                                            }
                                        })}

                                    </Select>
                                </FormControl>
                                {selectedCard == null && <>
                                    <p id={Styles.selectedCardWriteUp}><InfoIcon /> Here you will find the final submission your developer makes. When you have a job card that you have accepted a submission for it will show up in the drop box to select. Upon selecting the job you will be able to download the submission files for review.</p>
                                </>}
                            </div>
                            <div id={Styles.displayFilesWrapper}>
                                <div style={{ height: "100%", width: "80%" }}>
                                    {selectedCard != null && <>
                                        {files != null && files.length > 0 && <>
                                            <div id={Styles.uploadedFilesSelector}>
                                                <Checkbox
                                                    onChange={(e) => handleMasterCheck(e.target.checked)}
                                                    checked={masterChecked}
                                                    sx={{ color: "white" }}
                                                />
                                                <b style={{ color: "white" }}>Select all</b>
                                                <Button id={Styles.downloadButton} onClick={async () => await retrieveAndOpenDownloadLink()}>{downloadLoading ? `downloading${'.'.repeat(loadingState)}` : <>download selected</>}</Button>
                                            </div>
                                            <div id={Styles.fileWrapper}>
                                                <FormGroup>
                                                    {files != null && files.length > 0 && files.map((file, index) => {
                                                        return <FormControlLabel key={index} sx={{ marginRight: "0", color: "white", backgroundColor: index % 2 == 0 ? "var(--colour6)" : "var(--colour1)" }} control={<Checkbox sx={{ color: "white" }} checked={checkedFiles.includes(file)}
                                                            onChange={(e) => handleCheck(e.target.checked, file)} />} label={file} />
                                                    })}
                                                </FormGroup>
                                            </div>
                                        </>}
                                    </>}
                                    {selectedCard == null || (files != null && files.length == 0) && <>
                                        <p id={Styles.displayFileTileMessage}>Uploaded files will show up here</p>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div id={Styles.rightColumn}>
                            <div id={Styles.contolPanelWrapper}>
                                {selectedCard != null && <>
                                    <Accordion
                                        onChange={() => setAccordionExpand(!accordionExpand)}
                                        defaultExpanded={accordionExpand}
                                        style={{ backgroundColor: "var(--colour1)" }}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <AccordionSummary
                                            expandIcon={accordionExpand ? <RemoveIcon style={{ color: "white" }} /> : <AddIcon style={{ color: "white" }} />}>
                                            <h1 id={Styles.requestHeading}>Congratulations</h1>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <p className={Styles.requestParagraph}>Your new website has arrived! To continue check the &apos;Select All&apos; box on the left and then select &apos;download selected&apos; to download your site as a .zip file - or select individual files and click &apos;download selected&apos; to download select files.<b>Please note - this will only appear once the developer has submitted their work for review.</b></p>
                                                <p className={Styles.requestParagraph}>What&apos;s next? Well, thats up to you! We recommend testing out your site to make sure you are happy with it. And then? You have 3 options!</p>
                                                <p className={Styles.requestParagraph}>1: If you are not happy with the work provided to you - you may request changes up to two times prior to releasing payment to the developer.
                                                    To do this click on the <span style={{ color: "var(--colour3)" }}>&apos;Request Changes&apos;</span> button below, describe the changes you&apos;d like made; and an email will be sent to your developer.</p>
                                                <p className={Styles.requestParagraph}>2: If you ARE happy with the work provided to you and are happy to part ways with your developer, select the <span style={{ color: "var(--colour4)" }}>&apos;Release Payment&apos;</span> button found below to pay the developer for their hard work.</p>
                                                <p className={Styles.requestParagraph}>3: In the rare case that you believe you have not received the work that was sent to you, you can select the <span style={{ color: "red" }}>&apos;Report Submission&apos;</span> button found below. This
                                                    will send an email to our team for investigation. If it is deemed that the work provided was incorrect/falsified we will cancel the submission and we will re-activate your job card so you can either receive more submissions or
                                                    approve a different submission.
                                                </p>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <br />
                                    {selectedCard.paymentReleaseDeadline && releasePaymentDeadline.length > 0 && <h4 style={{ color: "red", textAlign: "center" }}>Note: Payment will automatically release in {releasePaymentDeadline[0]} {releasePaymentDeadline[1]}</h4>}
                                    {!selectedCard.paymentReleaseDeadline && <h4 style={{ color: "red", textAlign: "center" }}>Note: Automatic payment release is paused until developer notifies you again!</h4>}
                                    <br />
                                    <div id={Styles.requestButtonWrapper}>
                                        <Button disabled={selectedSubmission?.changeRequestsRemaining! <= 0}
                                            id={Styles.requestChangesButton}
                                            style={{ opacity: selectedSubmission?.changeRequestsRemaining! <= 0 ? "0.5" : "1" }}
                                            onClick={() => setDisplayRequestChangesModal(true)}>Request Changes - {selectedSubmission?.changeRequestsRemaining} Remaining</Button>
                                        <Button id={Styles.releasePaymentButton} onClick={() => setDisplayReleasePaymentModal(true)}>Release Payment</Button>
                                        <Button id={Styles.reportSubmissionButton} onClick={() => setDisplayReportSubmissionModal(true)}>Report Submission</Button>
                                    </div>
                                </>}

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>)
}
export default Requests
