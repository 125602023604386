import { Container, Row, Col } from "react-bootstrap"
import SignUpForm from "../components/sign-up/SignUpForm"
import Styles from '../css/sign-up/signUpForm.module.css'
import Logo from "../images/Logo.png"
import "../css/theme.css"
export default function SignUpPage() {
    return (
        <div id={Styles.signUpWrapper}>
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <SignUpForm />
                    </Col>
                    <Col xs={0} md={6}>
                        <img id={Styles.signUpLogo} src={Logo} alt="Build-My-Idea Logo"/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}