import { useEffect, useState } from "react";
import ProfilePage from "../components/shared/ProfileView";
import { RetrieveUserInfoViaUsername } from "../services/userService";
import { UserType } from "../types/UserType";
import { useParams } from "react-router-dom";
import "../css/theme.css"
const AltUserProfilePageWrapper = () => {
  const [user, setUser] = useState<UserType | undefined>();

  const { username } =  useParams();;
  
  useEffect(() => {
    const fetchUser = async () => {
      if (username) {
        setUser(await RetrieveUserInfoViaUsername(username));
      }
    };
    fetchUser();
  }, [username]); 

  return <ProfilePage user={user} />;
}
export default AltUserProfilePageWrapper