import Styles from "../css/terms-and-conditions/termsAndConditions.module.css"
import "../css/theme.css"
import returnPolicy from "../Text/returnPolicy"
const ReturnPolicy = () => {
    return (<>
        <div id={Styles.tsAndcsWrapper}>
            <div id={Styles.wrapper}>
                <h1>Privacy Policy</h1>
                <div id={Styles.textDisplay}>
                    {returnPolicy}
                </div>
            </div>
        </div></>)
}
export default ReturnPolicy