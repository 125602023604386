import { Dispatch, SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import { FilterType } from "../../types/FilterType";
import FilterBox from "../shared/FilterBox";
import { Button } from "@mui/material";
import "../../css/theme.css"
interface FilterProps {
    setFilterTags: Dispatch<SetStateAction<string[]>>;
    filters: FilterType[];
    setFilters: Dispatch<SetStateAction<FilterType[]>>;
    maxBounty: number;
    setRange: Dispatch<SetStateAction<string>>;
    onClose: () => void;
    showModal: boolean;
}
const FilterModal: React.FC<FilterProps> = ({ setFilterTags, filters, setFilters, maxBounty, setRange, onClose, showModal }) => {
    return (
        <Modal show={showModal} onHide={() => {
            onClose()
        }}>
            <Modal.Header closeButton style={{backgroundColor: "var(--colour1)", color: "white"}}>
                <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: "var(--colour1)", color: "white"}}>
                <FilterBox
                    setFilterTags={setFilterTags}
                    filters={filters}
                    setFilters={setFilters}
                    maxBounty={maxBounty}
                    setRange={setRange}
                />
                <Button onClick={() => onClose()}style={{backgroundColor: "var(--colour2)", marginTop: "5%", float: "right"}}>Confirm</Button>
            </Modal.Body>
        </Modal>
    );
}

export default FilterModal