import { CardType } from "../../types/CardType";
import { FilterType } from "../../types/FilterType";
import { UserType } from "../../types/UserType";
import { Chip } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal, Form, Button, InputGroup, Spinner } from "react-bootstrap"
import TagSelector from "../create-job/TagSelector";
import { updateCard } from "../../services/cardService";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import Styles from "../../css/job/editJobModal.module.css"
import { TechnologyTags, WebFrameworkTags } from "../../types/TechnologyTagsEnum";
import { jobDescriptionMaxChars, jobTitleMaxChars } from "../../Utils/valueSettings";
import "../../css/theme.css"

interface EditJobModalProps {
    showModal: boolean;
    onClose: () => void;
    cardDetails: CardType;
    setJobUpdatedSuccess: Dispatch<SetStateAction<boolean>>
    setCardDetails: Dispatch<SetStateAction<CardType | undefined>>
}
const EditJobModal: React.FC<EditJobModalProps> = ({ showModal, onClose, cardDetails, setJobUpdatedSuccess, setCardDetails }) => {
    const [updatedCard, setUpdatedCard] = useState(cardDetails)
    const [tags, setTags] = useState<FilterType[]>([])
    const [customTags, setCustomTags] = useState<string[]>([])
    const [images, setImages] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const handleSubmit = async () => {
        setLoading(true)
        const updatedCardCopy = { ...updatedCard };
        var convertedTags = tags.map(tag => {
            return Object.keys(tag.Type)[tag.Index]
        })

        convertedTags = convertedTags.concat(customTags)
        updatedCardCopy.tags = convertedTags
        await updateCard(updatedCardCopy)
        setLoading(false)
        setJobUpdatedSuccess(true)
        setCardDetails(updatedCardCopy)
        onClose()
    }
    const removeImage = (image: string) => {
        const updatedCardCopy = { ...updatedCard };
        image = image.replace("data:image/png;base64,", "")
        const index = images.indexOf(image);
        if (index !== -1) {
            images.splice(index, 1)
            updatedCardCopy.images = images;
            setImages(updatedCardCopy.images)
            setUpdatedCard(updatedCardCopy)
        }
    }
    const setUpChipsAsFilterType = () => {
        updatedCard.tags = updatedCard.tags ?? [];
        var chips = updatedCard.tags.map((tag) => {
            var matchingKey: string | undefined;
            if (tag != null && tag != "") {
                matchingKey = Object.keys(TechnologyTags).find(key => key.toLowerCase() === tag.toLowerCase());
                if (!matchingKey) {
                    matchingKey = Object.keys(WebFrameworkTags).find(key => key.toLowerCase() === tag.toLowerCase());
                    if (matchingKey) {
                        var indexOf = Object.keys(WebFrameworkTags).indexOf(matchingKey);
                        var webFrameworkChip = {
                            Type: WebFrameworkTags,
                            Index: indexOf
                        };
                        return webFrameworkChip;
                    }
                    if (!customTags.find(x => x == tag)) {
                        setCustomTags([...customTags, tag])
                    }
                    return null
                } else {
                    var indexOf = Object.keys(TechnologyTags).indexOf(matchingKey);
                    var technologyChip = {
                        Type: TechnologyTags,
                        Index: indexOf
                    };
                    return technologyChip;
                }
            }
        });
        // Filter out null chips (where no matching key was found)
        const filteredChips = chips.filter(chip => chip !== null) as FilterType[];
        setTags(filteredChips);
    };
    const updateImages = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (images.length >= 3) {
            return;
        }

        if (e.target.files != null && e.target.files.length > 0) {
            const files = Array.from(e.target.files);

            const promises = files.map((file) => {
                return new Promise<string>((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        const base64String = reader.result;
                        resolve(base64String?.toString() || "");
                    };
                });
            });
            const updatedCardCopy = { ...updatedCard };
            const base64Strings = await Promise.all(promises);
            updatedCardCopy.images = [...updatedCard.images ?? [], ...base64Strings.filter((s) => !!s)].slice(0, 3);
            updatedCardCopy.images = updatedCardCopy.images.map(image => image.replace(/^data:image\/[a-zA-Z]+;base64,/, ""));
            setImages(updatedCardCopy.images)
            setUpdatedCard(updatedCardCopy);
        }
    };
    const removeTag = (element: string) => {
        var newFilterList = tags.filter((tag) => Object.keys(tag.Type)[tag.Index] !== element)
        setTags(newFilterList)
    }
    const removeCustomTag = (customTag: string) => {
        var newFilterList = customTags.filter(filter => filter !== customTag)
        setCustomTags(newFilterList)
    }
    useEffect(() => {
        if (updatedCard) {
            setUpChipsAsFilterType()
            if (updatedCard.images != null && updatedCard.images.length > 0) {
                setImages(updatedCard.images)
            }
        }
    }, [updatedCard])
    return (<>
        <Modal show={showModal} onHide={onClose}>
            <Modal.Header closeButton style={{ backgroundColor: "var(--colour1)" }}>
                <Modal.Title style={{ color: "white" }}>Edit Job</Modal.Title>

            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "var(--colour1)" }}>
                <Form.Label style={{ color: "white" }}>Edit Title</Form.Label>
                <Form.Control value={updatedCard.jobTitle} maxLength={jobTitleMaxChars} onChange={(e) => {
                    const updatedCardCopy = { ...updatedCard };
                    updatedCardCopy.jobTitle = e.target.value;
                    setUpdatedCard(updatedCardCopy);
                }} />
                <p style={{color: updatedCard.jobTitle.length >= jobTitleMaxChars ? "red" : "white", textAlign: "right"}}>{updatedCard.jobTitle.length}/{jobTitleMaxChars}</p>
                <Form.Label style={{ color: "white" }}>Edit Description</Form.Label>
                <Form.Control as="textarea" maxLength={jobDescriptionMaxChars} rows={5} value={updatedCard.jobDescription} onChange={(e) => {
                    const updatedCardCopy = { ...updatedCard };
                    updatedCardCopy.jobDescription = e.target.value;
                    setUpdatedCard(updatedCardCopy);
                }} />
                <p style={{ textAlign: "right", color: (updatedCard.jobDescription.length >= jobDescriptionMaxChars) ? "red" : "white" }}>{updatedCard.jobDescription.length}/{jobDescriptionMaxChars}</p>
                <p style={{ color: "white" }}>Tags: {tags?.map((tag, index) => {
                    if (tag != null) {
                        return <Chip key={index}
                            onClick={() => removeTag(Object.keys(tag.Type)[tag.Index])}
                            label={Object.keys(tag.Type)[tag.Index]}
                            style={{ backgroundColor: Object.values(tag.Type)[tag.Index], cursor: "pointer", margin: "0.5%", color: "black" }} />
                    }
                })}</p>
                <p style={{ color: "white" }}>Custom Tags: {customTags.map((tag, index) => {
                    return <Chip key={index}
                        onClick={() => removeCustomTag(tag)}
                        label={tag}
                        style={{ backgroundColor: "var(--colour5)", cursor: "pointer", margin: "0.5%", color: "black" }} />
                })}</p>
                {(tags.length > 0 || customTags.length > 0) && <p style={{ textAlign: "right", color: tags.length + customTags.length == 7 ? "red" : "white" }}>{tags.length + customTags.length}/7</p>}
                <TagSelector setTags={setTags} tags={tags} customTags={customTags} setCustomTags={setCustomTags} />
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    {updatedCard.images != null && updatedCard.images.length > 0 &&
                        updatedCard.images.map((image, index) => {

                            return <div key={index} style={{ position: "relative", margin: "5px", objectFit: 'cover' }}>
                                <img
                                    src={"data:image/png;base64," + image}
                                    alt={`Image ${index + 1}`}
                                    className={Styles.image}
                                />
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        width: "fit-content",
                                        height: "fit-content",
                                        backgroundColor: "var(--colour6)",
                                        color: "white",
                                        borderRadius: "50%",
                                        padding: "4px",
                                        cursor: "pointer",
                                    }}
                                >
                                    <CancelIcon onClick={() => removeImage(image)} />
                                </div>
                            </div>
                        })}
                </div>
                <br />
                <input multiple type="file" style={{ display: "none" }} id="file-input" onChange={(e) => {
                    updateImages(e)
                }} />
                <div id={Styles.imageUploadWrapper}>
                    <label htmlFor="file-input" style={{ fontSize: "3.5vh", border: "2px solid #999", padding: "2%", cursor: "pointer", color: "white" }}><FileUploadIcon fontSize='large' />Select</label>
                    <br />
                </div>
                <p style={{ color: "#999", textAlign: "center" }}><small >Upload images for your project - Limit of 3</small></p>
                <p style={{ textAlign: "center", color: updatedCard.images!.length == 3 ? "green" : "white" }}>{updatedCard.images!.length}/3 uploaded</p>
                <Button id={Styles.submitButton} onClick={handleSubmit}>{loading ? <Spinner /> : <>Submit</>}</Button>
                <Button id={Styles.cancelButton} onClick={onClose}>Cancel</Button>
            </Modal.Body>
        </Modal>

    </>)
}
export default EditJobModal