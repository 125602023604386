import { Container, Row, Col } from "react-bootstrap"
import Styles from '../css/sign-up/signUpForm.module.css'
import SignUpDetailsForm from "../components/sign-up-details/SignUpDetailsForm"
import { useState } from "react"
import Logo from "../images/Logo.png"
import "../css/theme.css"
const SignUpDetailsPage = () => {
    const [profilePic, setProfilePic] = useState<string>()


    return (
        <Container>
            <Row>
                <Col xs={12} md={6}>
                    <SignUpDetailsForm setProfilePic={setProfilePic} profilePic={profilePic} />
                </Col>
                <Col>
                    {!profilePic && <img id={Styles.signUpLogo} src={Logo} />}
                    {profilePic && <img id={Styles.signUpProfilePic} src={profilePic} />}
                </Col>
            </Row>
        </Container>
    )
}
export default SignUpDetailsPage