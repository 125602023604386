import { Modal, Spinner } from "react-bootstrap";
import { UserType } from "../../types/UserType";
import Styles from "../../css/profile/releasePaymentModal.module.css"
import { Alert, Button } from '@mui/material';
import { useState } from "react";
import { PaymentStateEnum, SubmissionType } from "../../types/SubmissionType";
import { EmailType } from "../../types/EmailType";
import { sendEmail } from "../../services/emailService";
import { CardType } from "../../types/CardType";
import { updateCard } from "../../services/cardService";
import { deleteFile, updateSubmission } from "../../services/submissionService";
import { ReleasePaymentModalEmailToBMI, ReleasePaymentModalEmailToDeveloper, ReleasePaymentModalEmailToOwner } from "../../Text/emails";
import "../../css/theme.css"

interface Props {
    showModal: boolean;
    onClose: () => void;
    currentUser: UserType | undefined;
    submission: SubmissionType | undefined;
    card: CardType | undefined;
    bounty: number;
    handleSubmitReleasePaymentRequest: () => void;
    files: string[];
}
const ReleasePaymentModal: React.FC<Props> = ({ showModal, onClose, currentUser, submission, card, bounty, handleSubmitReleasePaymentRequest, files }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const sendReleasePaymentEmails = async () => {
        try {
            setLoading(true)
            var emailToBMI: EmailType = {
                subject: "New Payment Request Initiated",
                message: ReleasePaymentModalEmailToBMI(submission?.id!, submission?.posterEmail!, card?.jobBounty!),
                from: "buildmyidea.contact@gmail.com",
                to: "buildmyidea.contact@gmail.com",
                sendersName: "Build My Idea"
            }
            var emailToDeveloper: EmailType = {
                subject: "Congratulations your work is complete!",
                message: ReleasePaymentModalEmailToDeveloper(submission?.cardTitle!, bounty),
                from: "buildmyidea.contact@gmail.com",
                to: submission?.posterEmail!,
                sendersName: "Build My Idea Support"
            }
            var emailToOwner: EmailType = {
                subject: "Project Complete - Payment Has Been Released to Developer",
                message: ReleasePaymentModalEmailToOwner(card?.jobTitle!, currentUser?.firstName!),
                from: "buildmyidea.contact@gmail.com",
                to: currentUser?.email!,
                sendersName: "Build My Idea Support"
            }
            await sendEmail(emailToBMI)
            await sendEmail(emailToDeveloper)
            await sendEmail(emailToOwner)
            card!.isArchived = true
            submission!.paymentState = PaymentStateEnum.Pending
            await updateCard(card!)
            await updateSubmission(submission!)
            await deleteFile(card!.guid!, files, true)
            setLoading(false)
            handleSubmitReleasePaymentRequest()
            onClose()
        } catch {
            setError(true)
            setLoading(false)
            setTimeout(() => setError(false), 3000)
        }
    }
    return (
        <>
            <Modal show={showModal} onHide={onClose}>
                <Modal.Header closeButton closeVariant="white" style={{ backgroundColor: "var(--colour1)" }}>
                    <Modal.Title id={Styles.releasePaymentText}>Release Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body id={Styles.body}>
                    {error && <Alert severity="error">Something has gone wrong - please try again later</Alert>}
                    <p>Please note that this action cannot be undone - only release payment when you are completely happy with the work done by the developer.
                        Upon releasing payment, your job card will be archived and found in the <i>archived jobs</i> section of your dashboard. <br /><br />
                        <span style={{color: "red"}}>NOTE: Please make sure you have downloaded <u>ALL</u> files/folders as these cannot be retrieved once you have released payment</span>
                    </p>
                    <p>Are you sure you want to release payment?</p>
                    <div id={Styles.buttonsWrapper}>
                        <Button id={Styles.cancelButton} onClick={onClose}>Cancel</Button>
                        <Button id={Styles.releasePaymentButton} onClick={sendReleasePaymentEmails} disabled={submission?.paymentState != PaymentStateEnum.Unpaid} style={{opacity: submission?.paymentState != PaymentStateEnum.Unpaid ? "0.6": "1"}}>{loading ? <Spinner /> : <>Release</>}</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ReleasePaymentModal