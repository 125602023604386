import { StyledTextField, Theme } from "../components/shared/styledTextField"
import { ThemeProvider } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { Alert, Button, Form, Spinner } from "react-bootstrap"
import Styles from "../css/sign-up/activateAccount.module.css"
import { ActivateUserAccount, CheckAccountIsActivated, LogoutUser } from "../services/authService"
import { useNavigate } from "react-router-dom"
import CurrentUserContext from "../client/UserContextProvider"
import { AccountActivationStatusType } from "../types/AccountActivationStatusType"
import "../css/theme.css"
const ActivateAccount = () => {
    const context = useContext(CurrentUserContext);
    const { currentUser, clearCurrentUser } = context!
    const [token, setToken] = useState("")
    const [isActivated, setIsActivated] = useState(false)
    const [error, setError] = useState(false)
    const [resendCodeSuccess, setResendCodeSuccess] = useState(false)
    const [resendCodeFail, setResendCodeFail] = useState(false)
    const [resendCodeLoading, setResendCodeLoading] = useState(false)
    const [submitCodeLoading, setSubmitCodeLoading] = useState(false)
    var navigate = useNavigate();
    useEffect(() => {
        const checkLocalStorage = async () => {
            if (localStorage.getItem("email") == null) {
                if (currentUser) {
                    await LogoutUser()
                    clearCurrentUser()
                    navigate("/login")
                }
            }
        }
        checkLocalStorage()
    }, [currentUser])
    const onSubmit = async () => {
        setSubmitCodeLoading(true)
        if (token != "") {
            var success = await ActivateUserAccount(token)
            if (success) {
                if (context?.currentUser != null) {
                    await navigate("/policy/terms-and-conditions-accept")
                    window.location.reload();
                }
                setIsActivated(true)
            } else {
                setError(true)
                setTimeout(() => setError(false), 5000)
            }
        }
        setSubmitCodeLoading(false)
    }
    
    const backToLogin = async () => {
        if (currentUser) {
            await LogoutUser()
            clearCurrentUser()
        }
        navigate("/login")
    }
    const handleResendCode = async () => {
        try {
            setResendCodeLoading(true)
            var userAccountIsActivated: AccountActivationStatusType = await CheckAccountIsActivated(localStorage.getItem("email")!)
            if (userAccountIsActivated.activationTokenSent) {
                setResendCodeSuccess(true)
                setTimeout(() => { setResendCodeSuccess(false) }, 3000)
            } else {
                setResendCodeFail(true)
                setTimeout(() => setResendCodeFail(false), 3000)
            }
        } catch {
            setResendCodeFail(true)
            setTimeout(() => setResendCodeFail(false), 3000)
        }
        setResendCodeLoading(false)
    }
    return (
        <ThemeProvider theme={Theme}>
            <div id={Styles.container}>
                {resendCodeSuccess && <Alert className={Styles.alert} variant="success">Token was successfully resent to your email</Alert>}
                {resendCodeFail && <Alert className={Styles.alert} variant="danger">Token failed to resend - please try again later</Alert>}
                {error && <Alert className={Styles.alert} variant="danger">Token is incorrect or has expired. Click &apos;resend code&apos; and a new code will be emailed to you</Alert>}
                <div id={Styles.wrapper}>
                    {!isActivated && <Form onSubmit={(e) => {
                        e.preventDefault()
                        onSubmit()
                    }}>
                        <h1 className={Styles.heading}>We have <span style={{ color: "var(--colour5)" }}>sent a code to your email</span>. To <span style={{ color: "var(--colour3)" }}>activate your account</span>, please paste it here:</h1>
                        <StyledTextField
                            className={Styles.textField}
                            label="Token"
                            placeholder="eg 123456"
                            variant="standard"
                            onChange={(e) => {
                                setToken(e.target.value);
                            }}
                            value={token}
                            autoComplete="off"
                        />
                        <Button className={Styles.submitButtons} type="submit">{submitCodeLoading ? <Spinner /> : <>submit</>}</Button>
                        <Button id={Styles.resendCodeButton} onClick={handleResendCode}>{resendCodeLoading ? <Spinner /> : <>resend code</>}</Button>
                        <Button className={Styles.backButtons} onClick={backToLogin}>back</Button>
                    </Form>}
                </div>
            </div>
        </ThemeProvider>
    )
}
export default ActivateAccount