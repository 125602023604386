import CurrentUserContext from "../../client/UserContextProvider";
import { sendEmail } from "../../services/emailService";
import { ReportUserModalEmail } from "../../Text/emails";
import { EmailType } from "../../types/EmailType";
import { useState, useContext } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import "../../css/theme.css"

interface CommendationModalProps {
    showModal: boolean;
    onClose: () => void;
    userId: number;
    reportSubmittedAlert: () => void;
}
const ReportUserModal: React.FC<CommendationModalProps> = ({showModal, onClose, userId, reportSubmittedAlert}) => {

    const [loading, setLoading]= useState(false)
    const [message, setMessage] = useState("")
    const [maxCharAlert, setMaxCharAlert] = useState(false)
    const context = useContext(CurrentUserContext);
const { currentUser } = context!
    const maxCharacterLimit = 500;
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)
        if (message.length < maxCharacterLimit) {
            const emailData: EmailType = {
                from: currentUser?.email!,
                to: "buildmyidea.contact@gmail.com",
                message: ReportUserModalEmail(userId, message),
                subject: "NEW USER REPORT SUBMISSION",
                sendersName: currentUser?.firstName!
            }

            await sendEmail(emailData);
            setLoading(false)
            reportSubmittedAlert()
            onClose()
        } else {
            setMaxCharAlert(true)
            setTimeout(() => setMaxCharAlert(false), 3000)
        }
    }
    return (
        <Modal show={showModal} onHide={() => {
            setMessage("")
            onClose()
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Report A User</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="message">
                        {maxCharAlert && <Alert variant="danger">Message must be less than {maxCharacterLimit} characters</Alert>}
                        <Form.Label>Let us know why you are reporting this user</Form.Label>
                        <Form.Control
                            value={message}
                            as="textarea"
                            rows={4}
                            maxLength={maxCharacterLimit}
                            onChange={(e) => { setMessage(e.target.value) }}
                            placeholder={`Describe why you are reporting this user`}
                        />
                        <p>{message.length}/{maxCharacterLimit}</p>
                        <br />
                    </Form.Group>
                    {loading ? <Button type="submit" style={{float: "right"}} disabled>Sending <Spinner style={{width: "15px", height: "15px"}} /></Button> : <Button type="submit" style={{float: "right"}}>Send</Button>}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ReportUserModal;