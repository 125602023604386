import CurrentUserContext from "../../client/UserContextProvider";
import { updateCard } from "../../services/cardService";
import { sendEmail } from "../../services/emailService";
import { acceptSubmission, rejectSubmission } from "../../services/submissionService";
import { CardType } from "../../types/CardType";
import { EmailType } from "../../types/EmailType";
import { SubmissionType } from "../../types/SubmissionType";
import { useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Styles from "../../css/profile/dashboard.module.css";
import "../../css/theme.css"

import { acceptSubmissionModalText, acceptSubmissionOwnersNextStepsModalText } from "../../Text/emails";

interface SubmissionModalProps {
    showModal: boolean;
    onClose: () => void;
    submissionDetails: SubmissionType;
    cardDetails: CardType;
}
const AcceptSubmissionModal: React.FC<SubmissionModalProps> = ({showModal, onClose, submissionDetails, cardDetails}) => {
    const context = useContext(CurrentUserContext);
const { currentUser } = context!
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
            
            const emailData: EmailType = {
                from: currentUser?.email!,
                to: submissionDetails.posterEmail,
                message: acceptSubmissionModalText(cardDetails.jobTitle),
                subject: `Great News — Your Submission for ${cardDetails.jobTitle} Has Been Accepted!`,
                sendersName: currentUser?.firstName!
            }

            const ownerNextStepsEmail: EmailType = {
                from: "buildmyidea.contact@gmail.com",
                to: currentUser?.email!,
                message: acceptSubmissionOwnersNextStepsModalText(currentUser?.firstName!),
                subject: "Submission Approved — Next Steps For Your Project",
                sendersName: "BuildMyIdeaSupport"
            }

            onClose()
            cardDetails.submissions.forEach((x, index) => {
                if (x.id != submissionDetails.id) {
                    cardDetails.submissions[index].isRejected = true
                }
            })
            
            cardDetails.submissions.forEach(async x => {
                if (x.id != submissionDetails.id) {
                    await rejectSubmission(x);
                }
            })
            cardDetails.acceptedSubmission = true
            
            await acceptSubmission(submissionDetails)
            await updateCard(cardDetails)
            await sendEmail(emailData);
            await sendEmail(ownerNextStepsEmail)
    }
    return (
        <Modal show={showModal} onHide={() => {
            onClose()
        }}>
            <Modal.Header closeButton style={{backgroundColor: "var(--colour1)", color: "white"}}>
                <Modal.Title>Accept a submission</Modal.Title>

            </Modal.Header>
            <Modal.Body style={{backgroundColor: "var(--colour1)", color: "white"}}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="message">
                        <Form.Label>Only 1 submission may be accepted per project. The rest will be rejected and you cannot undo this action. Are you sure you want to continue?</Form.Label>
                        <div style={{marginBottom: "15px"}}/>
                    </Form.Group>
                        <Button className={Styles.acceptAgreeButton} type="submit">Yes</Button>
                        <Button className={Styles.acceptDisagreeButton} onClick={onClose}>No</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AcceptSubmissionModal;