import React, { useState, useContext } from 'react';
import { Container, TextField, Button, Typography, Box, AlertTitle, Alert, LinearProgress, makeStyles, styled } from '@mui/material';
import { sendEmail } from "../services/emailService";
import { EmailType } from "../types/EmailType";
import { ContactEmail } from '../Text/emails';
import "../css/theme.css"
const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
    },
    '& .MuiFormHelperText-root': {
        color: 'white',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
    },
    '& .MuiInputBase-input': {
        color: 'white', // Text color
    },
    '& .MuiInputLabel-root': {
        color: 'white', // Label color
    },
    '& .Mui-focused': {
        color: 'white', // Label color when focused
    },
});
const Contact: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const messageMaxCharacters = 500
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const emailData: EmailType = {
            from: email,
            to: 'buildmyidea.contact@gmail.com',
            message: ContactEmail(email, message, name),
            subject: `Contact Us: Message from ${name}`,
            sendersName: name
        }
        setLoading(true)

        await sendEmail(emailData);
        setMessage("")
        setName("");
        setEmail("");
        setLoading(false)
        setSuccessAlert(true)
        setTimeout(() => { setSuccessAlert(false) }, 4000)
    };

    return (
        <Container maxWidth="sm" sx={{ height: '80vh', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ color: "white", padding: '2rem', backgroundColor: "var(--colour1)", boxShadow: '0px 4px 15px var(--colour6)', borderRadius: 2 }} className="text-center">
                {successAlert && <Alert severity="success" sx={{ textAlign: 'left', marginBottom: 3 }}>
                    <AlertTitle>Email Sent</AlertTitle>
                    Thank you for your message!
                </Alert>}
                <Typography variant="subtitle1" gutterBottom style={{ color: "var(--colour5)" }}>Got a question?</Typography>
                <Typography variant="h3" component="h3" gutterBottom>
                    Get in touch
                </Typography>
                <Typography variant='subtitle2'>Let us know what we can help you with and we&apos;ll be in touch as soon as we can.</Typography>
                <form onSubmit={handleSubmit}>
                    <CustomTextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        inputProps={{ maxLength: 30 }}
                    />
                    <CustomTextField
                        label="Email"
                        type="email"
                        id="standard-basic"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        inputProps={{ maxLength: 40 }}
                    />
                    <CustomTextField
                        label="Message"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        inputProps={{ maxLength: messageMaxCharacters }}
                    />
                    <p style={{color: message.length >= messageMaxCharacters ? "red" : "white", float: "right"}}>{message.length}/{messageMaxCharacters}</p>
                    {!loading ?
                        <Button type="submit" variant="contained" fullWidth style={{ background: "var(--colour2)", border: "none", float: "right", margin: "1%", marginTop: 2 }}>Submit</Button>
                        : <LinearProgress />}
                </form>
            </Box>
        </Container>
    );
};

export default Contact;