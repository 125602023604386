import { Container, Button, Col} from "react-bootstrap"
import FaqCollapse from "../components/faq/faqCollapse";
import "../css/theme.css"
const FaqPage = () => {
    const faqOwnerData = [
        {
          question: "How does Build-My-Idea work for business owners?",
          answer: "Build-My-Idea connects business owners with talented developers to create custom websites. Owners can post job cards outlining their requirements, review developer submissions, and approve the final website for payment release."
        },
        {
          question: "What types of websites can I get built on Build-My-Idea?",
          answer: "You can get a wide range of websites built, including e-commerce sites, portfolio websites, blogs, informational websites, and more."
        },
        {
          question: "How do I ensure the quality of work from developers?",
          answer: "Owners have the opportunity to review developer profiles, portfolios, and submitted work before approving a website. Additionally, owners can request revisions to ensure the final product meets their expectations."
        },
        {
          question: "How do I communicate with developers?",
          answer: "Communication with developers can be done through various channels, including messaging on the platform, email, or video calls for direct feedback and discussion."
        },
        {
          question: "What if I'm not satisfied with the website?",
          answer: "If you're not satisfied with the initial submission, you can request revisions from the developer to address any concerns or changes needed."
        },
        {
          question: "How are payments handled on Build-My-Idea?",
          answer: "Payments are securely held in escrow until the website is approved by the owner. Once approved, the payment is released to the developer's designated bank account."
        }
      ];

      const faqDevData = [
        {
          question: "How do I join Build-My-Idea as a developer?",
          answer: "You can signup for a free developer account on the platform to start browsing job cards and submitting your work."
        },
        {
          question: "What kind of projects can I work on as a developer?",
          answer: "You can work on a variety of projects posted by business owners, ranging from simple portfolio websites to complex e-commerce sites."
        },
        {
          question: "How do I get paid for my work?",
          answer: "Developers earn a share of the subscription fees paid by business owners. Payments are released from escrow to the developer's designated bank account upon approval of the website by the owner."
        },
        {
          question: "Can I communicate directly with business owners?",
          answer: "Yes, developers can communicate directly with business owners through messaging on the platform, email, or video calls for clarifications and feedback."
        },
        {
          question: "How can I improve my chances of getting selected for a project?",
          answer: "Completing your profile with relevant skills and experience, showcasing a strong portfolio, and submitting high-quality work aligned with the owner's requirements can improve your chances of selection."
        },
        {
          question: "What if I encounter issues during a project?",
          answer: "If you encounter any issues during a project, you can reach out to our support team for assistance and guidance."
        }
      ];
      

    return (
        <>
        <h1 style={{textAlign: "center", color: "var(--colour2)"}} className="mt-5">Frequently Asked Questions</h1>
        <Container fluid >
                <Col style={{padding: "20px", textAlign: "center", marginTop: "50px"}}>
                        <h2 className="mt-3 mb-3" style={{color: "var(--colour5)"}}>Business Owners</h2>
                        {faqOwnerData.map((item, index) => ( 
                            <FaqCollapse
                                key={index}
                                question={item.question}
                                answer={item.answer}/>
                        ))}
                
                        <h2 className="mt-5 mb-3" style={{color: "var(--colour5)"}}>Developers</h2>
                        {faqDevData.map((item, index) => ( 
                            <FaqCollapse
                                key={index}
                                question={item.question}
                                answer={item.answer}/>
                        ))}
                </Col>
                <Container style={{textAlign: "center"}}>
                    <h2 style={{color: "var(--colour5)"}} className="m-3 mt-5"> Still have questions? </h2>
                    <Button style={{backgroundColor: "var(--colour5)", borderColor:"transparent"}} size="lg" >Contact Us</Button>
                </Container>
        </Container>
        </>
    )
}
export default FaqPage