import { RetrieveUserInfoViaSearchQuery } from "../../services/userService"
import { UserType } from "../../types/UserType"
import { useCallback, useState } from "react"
import { Form, InputGroup } from "react-bootstrap"
import SearchIcon from '@mui/icons-material/Search';
import Styles from "../../css/shared/menu.module.css"
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import "../../css/theme.css"

const UserSearch = () => {
    const [usersList, setUsersList] = useState<UserType[]>([])
    const [searchQuery, setSearchQuery] = useState("")
    const [displayList, setDisplayList] = useState(false)
    const navigate = useNavigate()
    const handleChange = async (query: string) => {
        if (query != "") {
            setUsersList(await RetrieveUserInfoViaSearchQuery(query))
        } else {
            setUsersList([])
        }
    }
    const handleClick = (user: UserType) => {
        navigate(`/profile/${user.username?.toLowerCase()}`)
        setSearchQuery("")
        setUsersList([])
    }
    const onFocus = async () => {
        setDisplayList(true)
    }
    const onBlur = () => {
        setTimeout(() => setDisplayList(false), 100)
    }
    const handleDebounce = useCallback(
        debounce((term: string) => {
            handleChange(term);
        }, 300),
        []
      );
    return (
        <>
            <Form>
                <div id={Styles.largeScreenSearch}>
                <InputGroup>
                    <Form.Control
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            handleDebounce(e.target.value)
                        }}
                        value={searchQuery}
                        id={Styles.search}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        autoComplete="off"
                    />
                    <InputGroup.Text>
                        <SearchIcon />
                    </InputGroup.Text>

                </InputGroup>
                    </div>
                <div className={Styles.dropdownContainer}>
                    {displayList && usersList?.length > 0 && usersList.map((x, index) => (
                            <li key={index} className={Styles.searchItem} onClick={() => handleClick(x)}>
                                {x.firstName} {x.lastName} <small style={{ fontSize: "x-small", opacity: "0.5" }}>{x.username}</small>
                            </li>
                    ))}
                    
                    {displayList && usersList.length == 0 && <li className={Styles.searchItem}>No users found</li>}
                </div>
            </Form>
        </>
    )
}
export default UserSearch