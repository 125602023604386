import { TextField, createTheme, styled } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
    color: theme.palette.text.primary,
    '& .MuiInput-underline:before': {
      borderBottomColor: '#999',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#999',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#999",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      background: "var(--colour6)"
    }
  }));

  export const Theme = createTheme({
    palette: {
      text: {
        primary: '#999',
        secondary: '#999',
      },
      primary: {
        main: '#ffffff',
      },
    },
  });