import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import Styles from "../css/terms-and-conditions/termsAndConditions.module.css"
import CurrentUserContext from "../client/UserContextProvider"
import { useContext, useEffect, useState } from "react"
import { UpdateUserInfo } from "../services/userService"
import { LogoutUser } from "../services/authService"
import { useNavigate } from "react-router-dom"
import termsAndConditions from "../Text/TsAndCs"
import "../css/theme.css"
const TermsAndConditionsAccept = () => {
    const context = useContext(CurrentUserContext)
    const { currentUser } = context!;
    const [accepted, setAccepted] = useState(false)
    var navigate = useNavigate()
    useEffect(() => {
        if (currentUser?.hasAcceptedTermsAndConditions) {
            navigate("/sign-up/details")
        }
    }, [currentUser])
    const handleSubmit = async () => {
        if (currentUser) {
            currentUser.hasAcceptedTermsAndConditions = true
            await UpdateUserInfo(currentUser)
            navigate("/sign-up/details")
            window.location.reload()
        }
    }
    const handleBack = async () => {
        await LogoutUser()
        navigate("/login")
        window.location.reload()
    }
    const handleChange = () => {
        setAccepted(!accepted)
    }
    return (
    <div id={Styles.tsAndcsWrapper}>
        <div id={Styles.wrapper}>
            <h1>Terms and Conditions</h1>
            <div id={Styles.textDisplay}>
            {termsAndConditions}
            </div>
            
            <div id={Styles.checkBox}>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={accepted} onChange={handleChange} />} label={"Accept"} />
                </FormGroup>
            </div>
            <Button id={Styles.backButton} onClick={handleBack}>Back</Button>
            <Button id={Styles.continueButton} onClick={handleSubmit} style={{ opacity: accepted ? "1" : "0.5" }} disabled={!accepted}>Continue</Button>
        </div>
    </div>)
}
export default TermsAndConditionsAccept