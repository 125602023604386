import Styles from "../css/terms-and-conditions/termsAndConditions.module.css"
import termsAndConditions from "../Text/TsAndCs"
import "../css/theme.css"
const TermsAndConditions = () => {

    return (
        <div id={Styles.tsAndcsWrapper}>
            <div id={Styles.wrapper}>
                <h1>Terms and Conditions</h1>
                <div id={Styles.textDisplay}>
                    {termsAndConditions}
                </div>
            </div>
        </div>
    )
}
export default TermsAndConditions