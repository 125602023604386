import Styles from "../css/terms-and-conditions/termsAndConditions.module.css"
import privacyPolicy from "../Text/privacyPolicy"
import "../css/theme.css"
const PrivacyPolicy = () => {

    return (
        <div id={Styles.tsAndcsWrapper}>
            <div id={Styles.wrapper}>
                <h1>Privacy Policy</h1>
                <div id={Styles.textDisplay}>
                    {privacyPolicy}
                </div>
            </div>
        </div>
    )
}
export default PrivacyPolicy