import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';
import React, { useState } from 'react';
import { Form, Modal } from "react-bootstrap";
import { Button, ThemeProvider } from "@mui/material";
import { StyledTextField, Theme } from "../shared/styledTextField";
import { UserType } from "../../types/UserType";
import { UpdateUserInfo } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import "../../css/theme.css"

interface Props {
    currentUser: UserType;
}
const PaymentEmailModal: React.FC<Props> = ({ currentUser }) => {
    const [value, setValue] = useState("")
    const [closeModal, setCloseModal] = useState(true)
    const navigate = useNavigate()
    const onSubmit = async () => {
        currentUser.paymentEmail = value
        await UpdateUserInfo(currentUser)
        setCloseModal(false)
    }
    return (
        <>
            <Modal show={(currentUser?.paymentEmail == "" || currentUser?.paymentEmail == null) && closeModal}>
                <Modal.Header style={{ backgroundColor: "var(--colour1)" }}>
                    <Modal.Title style={{ color: "white" }}>Please add payment email before continuing</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "var(--colour1)" }}>
                    <p style={{ color: "white" }}>We have noticed you dont have a payment email saved - this is how we will pay you for your work. Please nominate your Paypal email to receive your payment</p>
                    <Form onSubmit={async (e) => {
                        e.preventDefault()
                        await onSubmit()
                    }}>
                        <ThemeProvider theme={Theme}>
                            <StyledTextField variant="standard" label="PayPal Payment Email" type="email" fullWidth onChange={(e) => setValue(e.target.value)} />
                        </ThemeProvider>
                        <Button style={{ color: "white", backgroundColor: "var(--colour2)", marginRight: "5%", marginTop: "5%" }} onClick={() => navigate("/profile/dashboard")}>Back to Dashboard</Button>
                        <Button style={{ color: "white", backgroundColor: "green", marginTop: "5%", float: "right" }} type="submit" disabled={value.length < 2}>Submit</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PaymentEmailModal;


