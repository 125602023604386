import { Container, Button, Collapse } from "react-bootstrap"
import { useState } from 'react';
import "../../css/theme.css"
interface Props  {
    question: string;
    answer: string;
}
const FaqCollapse:React.FC<Props> = ({question, answer}) => {
    const [open, setOpen] = useState(false);

    return (
        <Container>
            <Button style={{backgroundColor: "transparent", borderColor: "transparent" }}onClick={() => setOpen(!open)} aria-controls="faq-text" aria-expanded={open}>
            { open ? "➖" : "➕" }
            <b style={{color: "gray"}}>{question}</b>
            </Button>
            <Collapse in={open}>
                <div id="faq-text">
                    <p style={{color: "gray"}}>{answer}</p>
                </div>
            </Collapse>
        </Container>
    )
}
export default FaqCollapse