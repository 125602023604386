import ReactDOMServer from "react-dom/server"
import EmailTemplate from "./emailTemplate"
import { Message } from "../types/EmailType"
import { SubmissionType } from "../types/SubmissionType"

const footer = <>
    <p>Cheers,</p>
    <p>The Build-My-Idea Team</p></>

export const submissionFormText = (customerFirstName: string, devFirstName: string, message: string, url: string, jobTitle: string): Message => {
    var header = <><h1 style={{ textAlign: 'left' }}>Hello {customerFirstName},</h1></>

    var body = <>
        <span>We are pleased to inform you that a new submission has been received for '<b>{jobTitle}</b>' from '<b>{devFirstName}</b>' with the following message:</span>
        <br/><br/>
        <span style={{backgroundColor: "#D3D3D3"}}>&#62; {message}</span>
        <br/><br/>
        <span>To review the submission and make your decision, please follow this link: {url}</span>
        <br/><br/>
        <span>Once you have reviewed the proposal, you can accept or reject the submission directly from your dashboard.</span>
        <br/><br/>
        <span>If you have any questions or would like to discuss the submission further, feel free to reply to this email. It will put you in contact with the developer directly.</span>
        <br/><br/>
        <span>Or, if you’d like to speak with the Build My Idea team, you can contact us here. https://www.build-my-idea.com/contact</span>
    </>
    
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const bugReportText = (message: string): Message => {
    var header = <><h1 style={{ textAlign: 'left' }}>Hello BMI Support,</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>I have found a bug or have a suggestion for Build My Idea:</span>
        <span>&#62; {message}</span>
    </p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
// export const bugReportToUserEmail = (firstName: string, jobTitle: string): Message => {
    // var header = <><h1 style={{ textAlign: 'left' }}>Hello {firstName},</h1></>
// 
    // var body = <><p style={{ fontSize: '14px' }}>
        {/* <span>Thank you for bringing this matter to our attention. We have received your report concerning {jobTitle} and will be conducting a thorough investigation as soon as possible.</span> */}
        {/* <br/><br/> */}
        {/* <span>We will keep you informed of our decision on the matter and will reach out to you with any updates.</span> */}
        {/* <br/><br/> */}
        {/* <span>We appreciate your vigilance and understanding.</span> */}
    {/* </p></> */}
    // 
    // var newMessage: Message = {
        // header: ReactDOMServer.renderToStaticMarkup(header),
        // body: ReactDOMServer.renderToStaticMarkup(body),
        // footer: ReactDOMServer.renderToStaticMarkup(footer)
    // }
    // return newMessage
// }
export const emailFormModalText = (posterName: string, currentUsersFirstName: string, message: string): Message => {
    var header = <><h1 style={{ textAlign: 'left' }}>Hello {posterName},</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>You have received a new message from {currentUsersFirstName}:</span>
        <p>&#62; {message}</p>
        <p>If you would like to respond - reply to this email!</p>
    </p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const acceptSubmissionModalText = (jobTitle: string): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Congratulations!</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>We are pleased to inform you that your submission for '<b>{jobTitle}</b>' has been accepted!.</span>
        <span>To proceed, please log in to your Build-My-Idea account and navigate to the Manage Submissions page. There, you will find detailed instructions for the next steps and can upload your work for final approval by the customer.</span>
        <br /><br />
        <span>If you have any questions or need assistance during this process, please don’t hesitate to reach out to us at build-my-idea.contact@gmail.com. We are here to help and look forward to seeing your work move forward.</span>
        <br /><br />
        <span>Congratulations and best of luck with the final steps!</span>
    </p></>
    var footer = <>
        <p>All the best,</p>
        <p>The Build My Idea Team</p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}

export const acceptSubmissionOwnersNextStepsModalText = (customerName: string): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello {customerName},</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>Congratulations on approving the proposal for your project.</span>
        <br/><br/>
        <span>Here’s what you can expect next:</span>
        <ol>
            <li><b>Developer Upload:</b> The developer will now upload the project files for your review.</li>
            <li><b>Notification:</b> Once the files are uploaded, you will receive a notification.</li>
            <li><b>Review Files:</b> Access the Manage Requests page to review the files submitted by the developer.</li>
            <li><b>Final Approval or Request Changes:</b> After reviewing the files, you can either give final approval or request changes. Please note that you have up to three opportunities to request changes.</li>
            <li><b>Payment Release:</b> Upon final approval, the payment for your job will be released to the developer.</li>
        </ol>
        <br/><br/>
        <span>We will keep you informed at each step of the process. Should you have any questions or need assistance, please feel free to reach out via this email or our Contact Us page.</span>
        <br/><br/>
        <span>Thank you for your collaboration. We look forward to completing this project successfully!</span>
    </p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const RejectSubmissionModalText = (jobTitle: string, link: string): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello,</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>Thank you for your recent submission for '<b>{jobTitle}</b>'. Unfortunately, your submission was not selected to move forward with this project.</span>
        <span>We truly appreciate the time and effort you invested in your work. Although this submission did not align with the specific needs of this project, we encourage you to continue exploring other opportunities listed on our home page. Your skills and creativity are valued, and we are confident that there are many more projects where your expertise will shine.</span>
        <br /><br />
        <span>If you have any questions or would like to request feedback on your submission, please feel free to contact us at build-my-idea.contact@gmail.com . We look forward to seeing your future submissions.</span>
    </p></>
    var footer = <>
        <p>All the best,</p>
        <p>The Build My Idea Team</p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const ReleasePaymentModalEmailToBMI = (id: number, posterEmail: string, jobBounty: number): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello!</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>A new payment request has arrived. <br />INFO: <br />Submission ID: {id} <br />Developer Email: {posterEmail} <br />Required Payment: ${jobBounty}</span>
    </p></>
    var footer = <>
        <p>Cheers,</p>
        <p>Build-My-Idea Team</p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const ReleasePaymentModalEmailToDeveloper = (cardTitle: string, bounty: number): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Congratulations!</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>We are pleased to inform you that your final submission for '{cardTitle}' has been reviewed and approved. As a result, the payment of ${bounty} has been released and is now being processed.</span>
        <br/><br/>
        <span>You can expect the funds to be deposited into your account within approximately 5 business days. If you have any questions or concerns during this time, please feel free to reach out to us.</span>
        <br/><br/>
        <span>Thank you for your hard work and contribution to this project.</span>
    </p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const ReleasePaymentModalEmailToOwner = (jobTitle: string, customerName: string): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello {customerName},</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>We are pleased to inform you that your payment for '<b>{jobTitle}</b>' has been successfully processed and released to the developer.</span>
        <br/><br/>
        <span>Thank you for choosing Build My Idea to bring your website to life. We hope the process has been smooth and that you’re satisfied with the final result. If you have any further needs or questions, please don't hesitate to reach out.</span>
        <br/><br/>
        <span>We appreciate your trust in us and look forward to working with you again in the future.</span>
    </p></>
    
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const ReportSubmissionModalEmailToBMI = (id: number, posterEmail: string, currentUserEmail: string, cardGuid: string, paymentReleaseDeadline: Date): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello!</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>A new submission report has arrived. <br />INFO: <br />Submission ID: {id} <br />Developer Email: {posterEmail!} <br /> Customers Email: {currentUserEmail} <br /> Card GUID: {cardGuid} <br />Card releases payment on: {paymentReleaseDeadline.toString()}</span>
    </p></>
    var footer = <>
        <p>Cheers,</p>
        <p>Build-My-Idea Team</p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const ReportSubmissionModalEmailToCustomer = (jobTitle: string): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello!</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>Thank you for your submission report for '<b>{jobTitle}</b>' - we have received this and will be investigating as soon as possible. We will contact you with our decision on the matter as soon as possible.</span>
    </p></>
    var footer = <>
        <p>Cheers,</p>
        <p>Build-My-Idea Team</p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const ReportUserModalEmail = (userId: number, message: string): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello!</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>NEW USER REPORT SUBMISSION <br />User Id: ${userId} <br />Report Message: ${message} <br />End of Report</span>
    </p></>
    var footer = <>
        <p>Cheers,</p>
        <p>Build-My-Idea Team</p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const ContactEmail = (email: string, message: string, customerName: string): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello!</h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>Reply To: ${email}<br />Hello BMI Support,<br />${message} <br />From, <br />${customerName}</span>
    </p></>
    var footer = <>
        <p>Cheers,</p>
        <p>Build-My-Idea Team</p></>
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}

export const ManageRequestsEmail = (message: string, submission: SubmissionType): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello, </h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>The owner for '<b>{submission.cardTitle}</b>' has reviewed your recent submission and has requested some changes. Below are the details provided:</span>
        <br /><br />
        <span style={{ backgroundColor: "#D3D3D3" }}>&#62; {message}</span>
        <br/><br/>
        <span>Please log in to your dashboard to review the job card. After making the necessary changes, you can upload your updated submission to the Manage Submissions page.</span>
        <br /><br />
        <span><b>Note:</b> The owner can request changes <b>{submission.changeRequestsRemaining - 1}</b> more times.</span>
        <br /><br />
        <span>If you have any questions or need further clarification, feel free to reach out.</span>
    </p></>
    
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}
export const NotifyCustomerEmail = (remainingChangeRequests: number): Message => {

    var header = <><h1 style={{ textAlign: 'left' }}>Hello, </h1></>

    var body = <><p style={{ fontSize: '14px' }}>
        <span>Congratulations - your brand new website files have been successfully uploaded by our developer! To download your website, simply go to https://www.build-my-idea.com/profile/dashboard/requests and select your job from the dropdown.</span>
        <br/><br/>
        <span>If you need assistance with the next steps, we also offer <b>hosting services</b> to ensure your website is up and running smoothly. To learn more, please reach out to us at buildmyidea.contact@gmail.com.</span>
        <br/><br/>
        <span>If you need further modifications, please make your requests through the Manage Requests page. If the files meet your expectations, you can proceed with final approval.</span>
        <br/><br/>
        <span><b>Remaining Change Requests:</b> You have <b>{remainingChangeRequests}</b> requests left. Remember, you have a total of 3 opportunities to request changes if needed.</span>
        <br/><br/>
        <span>We are here to assist you throughout this process. If you have any questions or need help, feel free to contact us via this email or our Contact Us page.</span>
        <br/><br/>
        <span>Thank you for your attention to this matter.</span>
    </p></>
    
    var newMessage: Message = {
        header: ReactDOMServer.renderToStaticMarkup(header),
        body: ReactDOMServer.renderToStaticMarkup(body),
        footer: ReactDOMServer.renderToStaticMarkup(footer)
    }
    return newMessage
}